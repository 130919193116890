import styled from 'styled-components';
import toRem from '../../../../../utils/toRem';
import { colours, fonts } from '../../../../../styles/theme';

type SideBarSectionStyledPropTypes = {
  readonly theme?: 'secondary'
}

export const SideBarStyled = styled.section`
  padding: 0 ${toRem(14)} ${toRem(14)};

  @media (min-width: 768px) {
    width: 25%;
    min-width: ${toRem(380)};
    padding: 0;    
  }
`;

export const SideBarSectionStyled = styled.div<SideBarSectionStyledPropTypes>`
  display: flex;
  padding: ${toRem(32)};
  border-radius: 16px;
  flex-direction: column;
  gap: ${toRem(24)};
  background: ${({ theme }) => (theme === 'secondary' ? colours.colour50 : colours.colour4)};
  border: 1px solid ${({ theme }) => (theme === 'secondary' ? colours.colour28 : colours.colour39)};
`;

export const SideBarTitleStyled = styled.h3`
  ${fonts.font1.H20B};
  margin-bottom: ${toRem(14)};
`;
