import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ISelectedHealthCategories,
  TeamMemberStateTypes,
} from '../components/ResultMainForm/types';
import { useAPI, useResearcherAPI, useUser } from '../../../hooks';
import { createStandeAloneResultValidations, emailValidation } from './validations';
import { VALIDATION_CONST } from './const';
import { regionalCentre } from '../../../utils';

export const useCreateStandAloneResults = () => {
  const navigate = useNavigate();

  const { getUserInfo } = useUser();
  const { getResearcherByEmail, createStandAloneResults } = useResearcherAPI();

  const {
    getHealthCategory,
    getHealthAuthorities,
    getHospitals,
  } = useAPI();

  const [resultInfo, setResultInfo] = useState<IStandAloneResultTypes>({
    status: 'pending_approval',
    is_completed: false,
    is_online: false,
    is_bc_support_unit_affiliation: false,
    is_por: false,
    is_ppo: false,
    province: 'bc',
  });
  const [healthCategories, setHealthCategories] = useState<IHealthCategoryOptions[]>([]);
  const [healthAuthorityRegions, setHealthAuthorityRegions] = useState<{ id: string, name: string }[]>([]);
  const [hospitals, setHospitals] = useState<{ value: string, label: string }[]>([]);

  const [teamMembers, setTeamMembers] = useState<Record<string, TeamMemberStateTypes>>({});
  const [collaborators, setCollaborators] = useState<Record<string, string>>({});
  const [collaboratingOrganizations, setCollaboratingOrganizations] = useState<Record<string, string>>({});
  const [selectedHealthCategories, setSelectedhealthCategories] = useState<ISelectedHealthCategories[]>([]);
  const [forceChange, setForceChange] = useState(false);
  const [selectedHealthAuthorityRegions, setSelectedHealthAuthorityRegions] = useState<string[]>([]);

  const [selectedRegionalCenters, setSelectedRegionalCenters] = useState<string[]>([]);

  const [resourceFile, setResourceFile] = useState<File>();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const updateResultInfoHandler = (field: string, value: any) => {
    let val = value.value || value;
    val = Number.isNaN(val) ? '' : val;

    setResultInfo((prev) => ({
      ...prev,
      [field]: val,
    }));
  };

  const addInputHandler = (type: string) => {
    const id = `${type}-${Math.round(Math.random() * 10000)}`;
    switch (type) {
      case 'teamMember':
        setTeamMembers((prev) => ({
          ...prev,
          [id]: { email: '', id: '' },
        }));
        break;

      case 'collaborator':
        setCollaborators((prev) => ({
          ...prev,
          [id]: '',
        }));
        break;
      case 'collaboratingOrganizations':
        setCollaboratingOrganizations((prev) => ({
          ...prev,
          [id]: '',
        }));
        break;
      default:
        break;
    }
  };

  const changeAddedInputHandler = (value: string, id: string, key: string) => {
    switch (key) {
      case 'collaborator':
        setCollaborators((prev) => ({
          ...prev,
          [id]: value,
        }));
        break;
      case 'teamMember':
        setTeamMembers((prev) => ({
          ...prev,
          [id]: { email: value, id: '' },
        }));
        break;
      case 'collaboratingOrganizations':
        setCollaboratingOrganizations((prev) => ({
          ...prev,
          [id]: value,
        }));
        break;
      default:
        break;
    }
  };

  const removeTeamMemberHandler = (id: string, name: string) => {
    if (Object.keys(teamMembers).length === 1) {
      const formErrors = { ...errors };
      formErrors[id] = VALIDATION_CONST.TEAM_MEMBERS.REMOVE;
      setErrors(formErrors);
      return;
    }

    const list = { ...teamMembers };
    delete list[id];
    const formErrors = errors;
    delete formErrors[name];
    setErrors({ ...formErrors });
    setTeamMembers(list);
  };

  const removeCollaboratorsHandler = (id: string) => {
    const list = { ...collaborators };
    delete list[id];
    setCollaborators(list);
  };

  const removeCollaboratingOrganizationsHandler = (id: string) => {
    const list = { ...collaboratingOrganizations };
    delete list[id];
    setCollaboratingOrganizations(list);
  };

  const removeInputHandler = (key: string, id: string, name?: string) => {
    switch (key) {
      case 'collaborator':
        removeCollaboratorsHandler(id);
        break;
      case 'teamMember':
        removeTeamMemberHandler(id, name || '');
        break;
      case 'collaboratingOrganizations':
        removeCollaboratingOrganizationsHandler(id);
        break;
      default:
        break;
    }
  };

  const setHealthCategoriesHandler = (
    name: string,
    id: number,
    category: string,
  ) => {
    const areas = selectedHealthCategories;
    const tempIndex = areas.findIndex((item) => item.id === id);
    if (tempIndex !== -1) {
      areas.splice(tempIndex, 1);
    } else {
      areas.push({ name, id, category });
    }
    setSelectedhealthCategories(areas);
    setForceChange(!forceChange);
  };

  const addRemoveItem = (list: string[], checked: boolean, id: string): string[] => {
    if (!checked && list.includes(id)) {
      return list.filter((item) => item !== id);
    }
    if (checked && !list.includes(id)) {
      return [...list, id];
    }

    return list;
  };

  const checkboxInputHandler = (field: string, checked: boolean, option: string) => {
    let list;
    switch (field) {
      case 'authorityRegions':
        if (option === 'all') {
          list = checked ? healthAuthorityRegions.map((region) => region.id) : [];
          setSelectedHealthAuthorityRegions(list);
        } else {
          list = addRemoveItem(selectedHealthAuthorityRegions, checked, option);
          setSelectedHealthAuthorityRegions(list);
        }
        break;
      case 'is_completed':
        setResultInfo({ ...resultInfo, is_completed: checked });
        break;
      case 'is_online':
        setResultInfo({ ...resultInfo, is_online: checked });
        break;
      case 'regional_centers':
        if (option === 'all') {
          list = checked ? Object.keys(regionalCentre).map((region) => region) : [];
          setSelectedRegionalCenters(list);
        } else {
          list = addRemoveItem(selectedRegionalCenters, checked, option);
          setSelectedRegionalCenters(list);
        }
        break;
      case 'is_por':
        setResultInfo({ ...resultInfo, is_por: checked });
        break;
      case 'is_ppo':
        setResultInfo({ ...resultInfo, is_ppo: checked });
        break;
      case 'is_bc_support_unit_affiliation':
        setResultInfo({ ...resultInfo, is_bc_support_unit_affiliation: checked });
        break;
      default:
        break;
    }
  };

  const fileHandler = (value: File, field: string) => {
    const currentErrors = { ...errors };

    if (value.type !== 'application/pdf' && value.type !== 'pdf') {
      errors[field] = 'Invalid file type, please select a pdf file';
      setErrors(currentErrors);
      return;
    }

    if (value && value.size >= 15 * 1024 * 1024) {
      errors[field] = 'Invalid file size, maximum file size 15MB.';
      setErrors(currentErrors);
      return;
    }

    if (value && field === 'resource_file') {
      setResourceFile(value);
      setResultInfo({ ...resultInfo, resource_file_name: value.name });
      delete errors.reb_file;

      setErrors(currentErrors);
    }
  };

  const removeFileHandler = () => {
    const currentErrors = { ...errors };
    setResourceFile(undefined);
    setResultInfo({ ...resultInfo, resource_file_name: undefined });
    setErrors(currentErrors);
  };

  const getFormValues = async () => {
    const user = getUserInfo();
    if (!teamMembers[user.id]) {
      teamMembers[user.id] = { id: user.id, email: user.email };
      setTeamMembers(teamMembers);
    }

    const healthCategoriesList = await getHealthCategory();
    setHealthCategories(healthCategoriesList);

    const healthAuthorityRegionList = await getHealthAuthorities();
    setHealthAuthorityRegions(healthAuthorityRegionList);

    const hospitalList = await getHospitals();
    const newArr = hospitalList.map((hospital: { id: number, name: string }) => ({
      value: hospital.id.toString(),
      label: hospital.name,
    }));

    setHospitals(newArr);
  };

  const checkForReseacherAccount = async (email: string, type: string, id: string) => {
    setIsSubmiting(true);
    try {
      await emailValidation.validate({ email });

      const researcher = await getResearcherByEmail(email.toLowerCase());
      const updatedErrors = { ...errors };

      if (Object.keys(researcher).length === 0) {
        switch (type) {
          case 'pi_email':
            setErrors({ ...errors, pi_email: VALIDATION_CONST.PI_ID.VALID });
            setResultInfo({ ...resultInfo, pi_id: undefined });
            break;
          case 'teamMembers':
            updatedErrors[id] = VALIDATION_CONST.TEAM_MEMBERS.VALID;
            setErrors(updatedErrors);
            break;
          default:
            break;
        }
        setIsSubmiting(false);
      } else {
        if (type === 'pi_email') {
          setResultInfo({
            ...resultInfo,
            pi_id: researcher.id,
          });
          delete updatedErrors.pi_email;
        }

        if (type === 'teamMembers' && id) {
          const updatedTeamMembers = teamMembers;
          updatedTeamMembers[id] = { email: researcher.email, id: researcher.id };
          setTeamMembers(updatedTeamMembers);

          delete updatedErrors[id];
        }
        setErrors(updatedErrors);
        setIsSubmiting(false);
      }
    } catch (error: any) {
      const errorMessages: { [key: string]: string } = { ...errors };

      switch (type) {
        case 'pi_email':
          errorMessages.pi_email = error.message;
          setResultInfo({ ...resultInfo, pi_id: undefined });
          break;
        case 'teamMembers':
          errorMessages[id] = error.message;
          break;
        default:
          break;
      }

      setErrors(errorMessages);
      setIsSubmiting(false);
    }
  };

  const submitHandler = async () => {
    setIsSubmiting(true);
    try {
      const standAloneResults: Record<string, any> = {
        ...resultInfo,
        teamMembers: Object.values(teamMembers),
        healthCategories: selectedHealthCategories,
        healthAuthorityRegions: selectedHealthAuthorityRegions,
        regional_centers: selectedRegionalCenters,
        created_at: new Date(),
      };

      if (Object.keys(collaborators).length > 0) standAloneResults.collaborators = Object.keys(collaborators).map((id) => collaborators[id]).join(',');

      if (Object.keys(collaboratingOrganizations).length > 0) standAloneResults.collaboratingOrganizations = Object.keys(collaboratingOrganizations).map((id) => collaboratingOrganizations[id]).join(',');

      const validedResutls = await createStandeAloneResultValidations.validate(standAloneResults, { abortEarly: false, stripUnknown: true });

      const data = new FormData();

      Object.keys(validedResutls).forEach((key) => {
        const value = validedResutls[key];

        if (key === 'created_at') {
          data.append(key, new Date(value).toISOString());
        } else if (typeof value === 'object' && value !== null) {
          data.append(key, JSON.stringify(value));
        } else {
          data.append(key, value);
        }
      });

      if (resourceFile) {
        data.append('resourceFile', resourceFile);
      }

      const results = await createStandAloneResults(data);
      setIsSubmiting(false);

      if (results.id) {
        navigate('/success/result', { state: { id: results.id, type: 'standAloneResult', resultType: 'standAloneResult' } });
      }
    } catch (error: any) {
      const validationErrors: { [key: string]: string } = {};

      if (error.inner) {
        error.inner.forEach((element: any) => {
          validationErrors[element.path] = element.message;
        });
        setErrors(validationErrors);
      }

      if (error.message) {
        setErrors(JSON.parse(error.message));
      }
      setIsSubmiting(false);
    }
  };

  useEffect(() => {
    getFormValues();
  }, []);

  return {
    teamMembers,
    collaborators,
    collaboratingOrganizations,
    addInputHandler,
    removeInputHandler,
    changeAddedInputHandler,

    selectedRegionalCenters,

    resultInfo,
    updateResultInfoHandler,

    healthAuthorityRegions,
    selectedHealthAuthorityRegions,
    checkboxInputHandler,

    healthCategories,
    selectedHealthCategories,
    setHealthCategoriesHandler,
    forceChange,

    fileHandler,
    resourceFile,
    removeFileHandler,

    hospitals,

    checkForReseacherAccount,
    submitHandler,
    isSubmiting,
    errors,
  };
};
