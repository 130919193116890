import styled from 'styled-components';
import { colours, fonts } from '../../../../../styles/theme';
import toRem from '../../../../../utils/toRem';

type LinkStyledStyledProps = {
  readonly selected: boolean
}
export const LinkStyled = styled.button<LinkStyledStyledProps>`
  transition: all .3s ease;
  cursor: pointer;
  ${({ selected }) => (selected ? fonts.font1.H14B : fonts.font1.H14B)};
  border: none;
  border-bottom: 2px solid ${({ selected }) => (selected ? colours.colour1 : 'transparent')};
  color: ${({ selected }) => (selected ? colours.colour5 : colours.colour35)};
  background: transparent;
  padding: ${toRem(8)} ${toRem(8)} ${toRem(12)};
  line-height: ${toRem(32)};

  &:hover {
    border-bottom: 2px solid ${colours.colour6};
  }

  @media (min-width: 770px) {
    padding: ${toRem(8)} 1rem ${toRem(12)};
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid ${colours.colour19};
  position: sticky;
  top: 0px;
  left: 0px;
  background: ${colours.colour4};
  margin-bottom: ${toRem(32)};
  padding: 0 ${toRem(16)};

  @media (min-width: 770px) {
    padding: 0;
  }
`;
