import React from 'react';
import { LinkStyled } from './styles';

type LinkProps = {
  children: React.ReactNode;
  theme?: string
  url: string
  linkWidth?: string
  newWindow?: boolean
  style?: Record<string, string>
};

export const LinkComponent = ({
  children,
  theme,
  url,
  linkWidth,
  newWindow,
  style,
}: LinkProps) => (
  <LinkStyled
    href={url}
    theme={theme}
    linkWidth={linkWidth}
    target={newWindow ? '_blank' : ''}
    style={style}
  >
    {children}
  </LinkStyled>
);
