import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSection from '../../../../../components/FormSelection';
import { RESULTS_MAIN_FORM } from '../const';
import * as Styled from '../styles';
import Button from '../../../../../components/Button';
import { StudyAffiliationSectionPropTypes } from '../types';
import FormSubtitle from '../../../../../components/FormSubtitle';
import FormRemoveableInput from '../../../../../components/FormRemoveableInput';
import { Checkbox } from '../../../../../components';
import { regionalCentre } from '../../../../../utils';

export const StudyAffiliationSection = ({
  resultInfo,
  addInputHandler,
  changeAddedInputHandler,
  collaboratingOrganizations,
  removeInputHandler,
  selectedRegionalCenters,
  checkboxInputHandler,
}: StudyAffiliationSectionPropTypes) => (
  <FormSection
    title={resultInfo.is_ppo
      ? RESULTS_MAIN_FORM.AFFILIATIONS.TITLE_OPPORTUNTIY
      : RESULTS_MAIN_FORM.AFFILIATIONS.TITLE}
  >
    <Styled.FormChildrenWrapper>
      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.COLLABORATING_ORGANIZATION.TITLE}
          margin="xsmall"
        />
        <Styled.FormDescriptionStyles theme="secondary">
          {RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.COLLABORATING_ORGANIZATION.DESCRIPTION}
        </Styled.FormDescriptionStyles>
        {Object.keys(collaboratingOrganizations).length > 0 && (
          <Styled.InputsWrapper>
            {Object.entries(collaboratingOrganizations).map(([key, value]) => (
              <FormRemoveableInput
                id={key}
                field="collaboratingOrganizations"
                textValue={value}
                handleChange={changeAddedInputHandler}
                handleRemoveInput={removeInputHandler}
              />
            ))}
          </Styled.InputsWrapper>
        )}
        <Button
          id={RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.COLLABORATING_ORGANIZATION.BUTTON.ADD}
          onClick={() => addInputHandler('collaboratingOrganizations')}
          buttonWidth="fit-content"
        >
          <Styled.ButtonChildrenWrapper>
            <FontAwesomeIcon icon="plus" />
            {RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.COLLABORATING_ORGANIZATION.BUTTON.ADD}
          </Styled.ButtonChildrenWrapper>
        </Button>
      </div>

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.TITLE}
          margin="xsmall"
          link={{
            url: RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.LINK.PATH,
            label: RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.LINK.LABEL,
          }}
        />
        <Styled.FormDescriptionStyles>
          {RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.DESCRIPTION}
        </Styled.FormDescriptionStyles>
        <Checkbox
          label={RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.LABEL}
          isChecked={resultInfo.is_bc_support_unit_affiliation}
          onChange={(checked) => checkboxInputHandler(
            RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.NAME,
            checked,
            RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.AFFILIATION.NAME,
          )}
          textTransform="none"
        />

        {resultInfo.is_bc_support_unit_affiliation && (
          <Styled.CheckboxGroupWrapper>
            <p>{RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.BC_SUPPORT_UNIT.LABEL}</p>
            <Styled.CheckboxWrapper>
              <Checkbox
                label={RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.BC_SUPPORT_UNIT.DEFAULT}
                isChecked={selectedRegionalCenters.length === Object.keys(regionalCentre).length}
                onChange={(checked) => checkboxInputHandler(
                  RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.BC_SUPPORT_UNIT.NAME,
                  checked,
                  'all',
                )}
              />
            </Styled.CheckboxWrapper>
            {Object.keys(regionalCentre).map((region) => (
              <Styled.CheckboxWrapper key={region}>
                <Checkbox
                  label={regionalCentre[region]}
                  isChecked={selectedRegionalCenters.includes(region)}
                  onChange={(checked) => checkboxInputHandler(
                    RESULTS_MAIN_FORM.AFFILIATIONS.INPUTS.BC_SUPPORT_UNIT.NAME,
                    checked,
                    region,
                  )}
                />
              </Styled.CheckboxWrapper>
            ))}
          </Styled.CheckboxGroupWrapper>
        )}
      </div>
    </Styled.FormChildrenWrapper>
  </FormSection>
);
