import React from 'react';
import AppWrapper from '../../../components/AppWrapper';
import { useDetailStandAloneResult } from './useDetailStandAloneResult';
import Hero from '../../../components/Hero';
import { MainDetailBody, ResultSideBar, ResultTabs } from './components';
import { STAND_ALONE_RESULT_CONST } from './const';
import * as Styled from './styles';

export const DetailStandAloneResult = () => {
  const hook = useDetailStandAloneResult();

  return (
    <AppWrapper
      footer
      alternateFooter={hook.user?.type === 'volunteer'}
    >
      <Hero
        type="standAloneResult"
        title={hook.resultInfo?.nick_name || ''}
        bottomSubtitle={hook.resultInfo?.title || ''}
        isComplete={hook.resultInfo?.is_completed}
        currentTab=""
        setCurrentTab={() => ''}
      />

      <ResultTabs
        currentTab={hook.currentTab}
        setCurrentTab={hook.setCurrentTabHandler}
        tabs={STAND_ALONE_RESULT_CONST.TABS}
      />
      {hook.resultInfo && (
        <Styled.ResultWraper>
          <MainDetailBody
            result={hook.resultInfo}
            sectionRefs={hook.scrollHook.sectionRefs}
          />
          <ResultSideBar
            result={hook.resultInfo}
          />
        </Styled.ResultWraper>
      )}
    </AppWrapper>
  );
};
