import styled from 'styled-components';
import { colours, fonts } from '../../../styles/theme';
import toRem from '../../../utils/toRem';

type CardTitleStyledType = {
  readonly theme?: 'secondary'
}

export const HeroWrapper = styled.div`
  max-width: ${toRem(1168)};
  margin: 0 auto;
    padding: ${toRem(64)} ${toRem(16)} ${toRem(56)};

  @media (min-width: 650px) {
    padding: ${toRem(64)} 0 ${toRem(56)};
  }    
`;

export const TitleWrapper = styled.div`
  ${fonts.font1.H40B};
  line-height: ${toRem(40)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(56)};

    @media (min-width: 650px) {
    flex-direction: row;
  }    
`;

export const LinkImageStyled = styled.img`
  margin: 0 0.25rem;
`;

export const LinkContainWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BodyWrapper = styled.div`
  display: flex;
  gap:${toRem(32)};
  flex-wrap: wrap;
  flex-direction: column;
  
  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: ${colours.colour4};
  box-shadow: 0px 2px 4px 0px rgba(32, 37, 50, 0.03), 0px 2px 8px 0px rgba(32, 37, 50, 0.08);
  padding: ${toRem(32)};
  ${fonts.font1.H16}
  line-height: ${toRem(24)};
  justify-content: space-between;
`;

export const CardTitleStyled = styled.h3<CardTitleStyledType>`
  ${fonts.font1.H20B};
  margin-bottom: ${toRem(24)};
  line-height: ${toRem(32)};
  color: ${({ theme }) => (theme === 'secondary' ? colours.colour8 : colours.colour1)};
`;

export const CardDescriptionWrapper = styled.div`
  margin-bottom: ${toRem(48)};
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const BoldedSectionStyled = styled.span`
font-weight: 700;
`;

export const ListStyled = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  margin-left: ${toRem(16)};
`;

export const LinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${toRem(8)};
`;
