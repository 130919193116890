import React from 'react';
import * as Styled from '../styles';
import FormSection from '../../../../../components/FormSelection';
import { TextArea } from '../../../../../components/TextArea.tsx';
import { RESULTS_MAIN_FORM } from '../const';
import { AdminCommentSectionPropTypes } from '../types';

export const AdminCommentSection = ({
  resultInfo,
}: AdminCommentSectionPropTypes) => (
  <FormSection noHeader>
    <Styled.FormChildrenWrapper>
      <TextArea
        textValue={resultInfo.admin_comments || ''}
        setTextValue={() => ''}
        label={RESULTS_MAIN_FORM.ADMIN_COMMENTS.INPUTS.COMMENT.LABEL}
        isDisabled
        theme="form"
        isRequired={false}
      />
    </Styled.FormChildrenWrapper>
  </FormSection>
);
