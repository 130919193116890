import React from 'react';
import FormSection from '../../../../../components/FormSelection';
import FormSubtitle from '../../../../../components/FormSubtitle';
import { RESULTS_MAIN_FORM } from '../const';
import * as Styled from '../styles';
import { StudyLocationSectionPropTypes } from '../types';
import { Checkbox } from '../../../../../components';
import { ErrorMessage } from '../../../../../components/ErrorMessage';

export const StudyLocationSection = ({
  healthAuthorityRegions,
  checkboxInputHandler,
  selectedHealthAuthorityRegions,
  errors,
}: StudyLocationSectionPropTypes) => (
  <FormSection title={RESULTS_MAIN_FORM.LOCATION.TITLE} subText={RESULTS_MAIN_FORM.LOCATION.DESCRIPTION}>
    <FormSubtitle
      subtitle={RESULTS_MAIN_FORM.LOCATION.INPUTS.REGIONS.LABEL}
      link={{
        label: RESULTS_MAIN_FORM.LOCATION.INPUTS.REGIONS.LINK.LABEL,
        url: RESULTS_MAIN_FORM.LOCATION.INPUTS.REGIONS.LINK.PATH,
      }}
      margin="xsmall"
      isRequired
    />
    <Styled.FormDescriptionStyles>
      {RESULTS_MAIN_FORM.LOCATION.INPUTS.REGIONS.DESCRIPTION}
    </Styled.FormDescriptionStyles>
    <Styled.CheckboxWrapper>
      <Checkbox
        label="All"
        isChecked={selectedHealthAuthorityRegions.length === healthAuthorityRegions.length}
        onChange={(check) => checkboxInputHandler('authorityRegions', check, 'all')}
      />
    </Styled.CheckboxWrapper>

    {healthAuthorityRegions.map((option) => (
      <Styled.CheckboxWrapper key={option.name || option.id}>
        <Checkbox
          label={option.name}
          isChecked={selectedHealthAuthorityRegions.includes(option.id)}
          onChange={(check) => checkboxInputHandler('authorityRegions', check, option.id)}
        />
      </Styled.CheckboxWrapper>
    ))}
    {errors?.healthAuthorityRegions && <ErrorMessage message={errors.healthAuthorityRegions} />}
  </FormSection>
);
