import React from 'react';
import * as Styled from './styles';
import { ResultSideBarPropTypes } from './types';
import { STAND_ALONE_RESULT_CONST } from '../../const';
import SideBarItem from '../../../../../components/ProjectDetails/components/SideBarItem';

export const ResultSideBar = ({
  result,
}: ResultSideBarPropTypes) => (
  <Styled.SideBarStyled>
    <Styled.SideBarSectionStyled>
      <Styled.SideBarTitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.INFORMATION}
      </Styled.SideBarTitleStyled>
      <SideBarItem
        subTitle={STAND_ALONE_RESULT_CONST.LABEL.COLLABORATING_ORGANIZATION}
        icon="building"
      >
        {result.collaborating_organizations}
      </SideBarItem>

      <SideBarItem
        subTitle={STAND_ALONE_RESULT_CONST.LABEL.BC_AFFILIATION}
        icon="building"
      >
        {result.is_bc_support_unit_affiliation}
      </SideBarItem>
    </Styled.SideBarSectionStyled>

    <Styled.SideBarSectionStyled>
      <Styled.SideBarTitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.LOCATION}
      </Styled.SideBarTitleStyled>
      <SideBarItem
        subTitle={STAND_ALONE_RESULT_CONST.LABEL.PARTICIPATION_TYPE}
        icon="map-marker-alt"
      >
        <p>{result.is_online ? 'Online' : 'In Person'}</p>
      </SideBarItem>

      <SideBarItem
        subTitle={STAND_ALONE_RESULT_CONST.LABEL.BC_AFFILIATION}
        icon="building"
      >
        {result.is_bc_support_unit_affiliation}
      </SideBarItem>
    </Styled.SideBarSectionStyled>
  </Styled.SideBarStyled>
);
