import styled from 'styled-components';
import { colours, fonts } from '../../../styles/theme';
import toRem from '../../../utils/toRem';

type GridCellStylesTypes = {
  readonly theme?: 'secondary'
};

export const TableGridStyled = styled.div`
  max-height: 225px;
  overflow-y: auto;
  position: relative;

/* hide scroll bar for side menu */
  -ms-overflow-style: none; 
  scrollbar-width: none;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 2fr;
  position: sticky;
  top: 0;
  background: ${colours.colour4};
`;

export const TableBodyStyled = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 2fr;
  border-bottom: 1px solid ${colours.colour41};
`;

export const GridCellStyles = styled.div<GridCellStylesTypes>`
  display: flex;
  align-items: center;
  padding: ${toRem(16)} ${toRem(8)};
  ${({ theme }) => (theme === 'secondary' ? fonts.font1.H14 : fonts.font1.H14B)};
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HeaderButton = styled.div`
  ${fonts.font1.H14B};
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
  padding: 0 ${toRem(8)} ${toRem(16)} ;
`;

export const LabelStyled = styled.label`
  margin-left: 0.875rem;
  line-height: ${toRem(20)};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;
