import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput } from '../TextInput';
import { TextP } from '../Text';
import { FlexWrapper, ButtonStyled, ButtonTextWrapper } from './styles';

type FormRemoveableInputProps = {
  id: string
  textValue: string
  field: string
  disabled?: boolean
  handleChange: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string, name?: string) => void
  onBlurAction?: () => void
  error?: string
  name?: string
  increaseMargin?: boolean
  placeholder?: string
};

const FormRemoveableInput = ({
  id, textValue, field, handleChange, handleRemoveInput, disabled, onBlurAction, error, name, increaseMargin, placeholder,
}: FormRemoveableInputProps) => (
  <FlexWrapper alignItems="start" increaseMargin={increaseMargin}>
    <TextInput
      isRequired={false}
      textValue={textValue}
      setTextValue={(value) => handleChange(value, id, field)}
      onBlurAction={onBlurAction}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
    />
    <ButtonStyled
      id={`remove-${field}`}
      theme="remove"
      onClick={() => {
        if (field === 'teamMember') {
          handleRemoveInput(field, id, name);
        } else {
          handleRemoveInput(field, id);
        }
      }}
      disabled={disabled}
    >
      <ButtonTextWrapper>
        <FontAwesomeIcon icon="xmark" />
        <TextP>Remove</TextP>
      </ButtonTextWrapper>
    </ButtonStyled>
  </FlexWrapper>
);

export default FormRemoveableInput;
