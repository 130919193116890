import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RESEARCHER_DASHBOARD_HERO } from './const';
import { LinkComponent } from '../../Link';
import QuestionSvg from '../../../assets/images/question_circle.svg';
import * as Styled from './styles';
import { UserNotice } from '../../UserNotice';
import Button from '../../Button';

type ResearcherDashboardHeroPropTypes = {
  userName: string
  bypass?: boolean
  openModelHandler: () => void
}

export const ResearcherDashboardHero = ({ userName, bypass = false, openModelHandler }: ResearcherDashboardHeroPropTypes) => (
  <Styled.HeroWrapper>
    {bypass && (
      <UserNotice
        title={RESEARCHER_DASHBOARD_HERO.BYPASS_MESSAGE.TITLE}
        description={RESEARCHER_DASHBOARD_HERO.BYPASS_MESSAGE.DESCRIPTION}
      />
    )}
    <Styled.TitleWrapper>
      <h3>{RESEARCHER_DASHBOARD_HERO.USER_MESSAGE} {userName}</h3>

      <LinkComponent
        url={RESEARCHER_DASHBOARD_HERO.LINK.URL}
        theme="plainSecondary"
      >
        <Styled.LinkContainWrapper>
          <Styled.LinkImageStyled src={QuestionSvg} alt="qm" />
          {RESEARCHER_DASHBOARD_HERO.LINK.LABEL}
        </Styled.LinkContainWrapper>
      </LinkComponent>
    </Styled.TitleWrapper>

    <Styled.BodyWrapper>
      <Styled.CardWrapper>
        <div>
          <Styled.CardTitleStyled>{RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.TITLE}</Styled.CardTitleStyled>
          <Styled.CardDescriptionWrapper>
            <p>
              {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION}
            </p>

            <p>
              {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION_SECONDARY.START}
              <Styled.BoldedSectionStyled>
                {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION_SECONDARY.BOLDED}
              </Styled.BoldedSectionStyled>
              {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION_SECONDARY.END}
            </p>
          </Styled.CardDescriptionWrapper>
        </div>

        <LinkComponent
          url={RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.LINK.URL}
          theme="button"
          linkWidth="fit-content"
        >
          <Styled.LinkButtonWrapper
            style={{ borderRadius: '6px', padding: '0.5rem 1.25rem' }}
          >
            <FontAwesomeIcon icon="plus" />
            {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.LINK.LABEL}
          </Styled.LinkButtonWrapper>
        </LinkComponent>
      </Styled.CardWrapper>

      <Styled.CardWrapper>
        <div>
          <Styled.CardTitleStyled>
            {RESEARCHER_DASHBOARD_HERO.Cards.PPO.TITLE}
            <br />
            {RESEARCHER_DASHBOARD_HERO.Cards.PPO.TITLE_SECOND_LINE}
          </Styled.CardTitleStyled>
          <Styled.CardDescriptionWrapper>
            <p>
              {RESEARCHER_DASHBOARD_HERO.Cards.PPO.DESCRIPTION}
            </p>
          </Styled.CardDescriptionWrapper>
        </div>

        <LinkComponent
          url={RESEARCHER_DASHBOARD_HERO.Cards.PPO.LINK.URL}
          theme="button"
          linkWidth="fit-content"
        >
          <Styled.LinkButtonWrapper
            style={{ borderRadius: '6px', padding: '0.5rem 1.25rem' }}
          >
            <FontAwesomeIcon icon="plus" />
            {RESEARCHER_DASHBOARD_HERO.Cards.PPO.LINK.LABEL}
          </Styled.LinkButtonWrapper>
        </LinkComponent>
      </Styled.CardWrapper>

      <Styled.CardWrapper>
        <Styled.CardTitleStyled theme="secondary">
          {RESEARCHER_DASHBOARD_HERO.Cards.RESULTS.TITLE}
        </Styled.CardTitleStyled>
        <Styled.CardDescriptionWrapper>
          <p>
            {RESEARCHER_DASHBOARD_HERO.Cards.RESULTS.DESCRIPTION}
          </p>

          <p>
            {RESEARCHER_DASHBOARD_HERO.Cards.RESULTS.DESCRIPTION_SECONDARY.START}
            <Styled.BoldedSectionStyled>
              {RESEARCHER_DASHBOARD_HERO.Cards.RESULTS.DESCRIPTION_SECONDARY.BOLDED}
            </Styled.BoldedSectionStyled>
            {RESEARCHER_DASHBOARD_HERO.Cards.RESULTS.DESCRIPTION_SECONDARY.END}
          </p>

          <Styled.ListStyled>
            {RESEARCHER_DASHBOARD_HERO.Cards.RESULTS.LIST.map((listItem) => (
              <li key={listItem}>
                {listItem}
              </li>
            ))}
          </Styled.ListStyled>

          <p>
            {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION_SECONDARY.START}
            <Styled.BoldedSectionStyled>
              {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION_SECONDARY.BOLDED}
            </Styled.BoldedSectionStyled>
            {RESEARCHER_DASHBOARD_HERO.Cards.STUDIES.DESCRIPTION_SECONDARY.END}
          </p>
        </Styled.CardDescriptionWrapper>
        <Button
          id="add"
          onClick={openModelHandler}
          style={{
            padding: '0.5rem 1.25rem',
            borderRadius: '6px',
          }}
        >
          <Styled.LinkButtonWrapper>
            <FontAwesomeIcon icon="plus" />
            {RESEARCHER_DASHBOARD_HERO.Cards.PPO.LINK.LABEL}
          </Styled.LinkButtonWrapper>
        </Button>
      </Styled.CardWrapper>
    </Styled.BodyWrapper>
  </Styled.HeroWrapper>
);
