import React from 'react';
import { ResultSideMenuPropTypes } from './types';
import { RESULT_SIDE_MENU, RESULT_STATUS, STATUS_ICON } from './const';
import StatusTag from '../../../../components/StatusTag';
import Button from '../../../../components/Button';
import * as Styled from './styles';

export const ResultSideMenu = ({
  status,
  submitHandler,
}: ResultSideMenuPropTypes) => (
  <Styled.SideMenuWrapper>
    <Styled.StatusWrapper>
      <StatusTag
        label={RESULT_STATUS[status]}
        theme={status}
        icon={STATUS_ICON[status]}
        isRound
        useIcon={STATUS_ICON[status]}
      />
    </Styled.StatusWrapper>

    <Button
      id="result_submit"
      onClick={submitHandler}
      theme="primary"
    >
      {RESULT_SIDE_MENU.BUTTON}
    </Button>
  </Styled.SideMenuWrapper>
);
