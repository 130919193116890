import React from 'react';
import * as Styled from '../styles';
import FormSection from '../../../../../components/FormSelection';
import { RESULTS_MAIN_FORM } from '../const';
import { StudyDetailSectionPropTypes } from '../types';
import { Checkbox, TextInput, TextP } from '../../../../../components';
import { TextArea } from '../../../../../components/TextArea.tsx';
import FormSubtitle from '../../../../../components/FormSubtitle';
import HealthResearchAreas from '../../../../../components/HealthResearchAreas';
import { fonts } from '../../../../../styles/theme';

export const StudyDetailSection = ({
  updateResultInfoHandler,
  resultInfo,
  categoriesMap,
  checkboxInputHandler,
  setHealthCategoriesHandler,
  selectedHealthCategories,
  forceChange,
  errors,
}: StudyDetailSectionPropTypes) => (
  <FormSection
    title={RESULTS_MAIN_FORM.DETAILS.TITLE}
  >
    <Styled.FormChildrenWrapper>
      <TextInput
        label={RESULTS_MAIN_FORM.DETAILS.INPUTS.TITLE.LABEL}
        description={RESULTS_MAIN_FORM.DETAILS.INPUTS.TITLE.DESCRIPTION}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.DETAILS.INPUTS.TITLE.NAME, value)}
        textValue={resultInfo.title || ''}
        maxLength={RESULTS_MAIN_FORM.DETAILS.INPUTS.TITLE.COUNT}
        theme="form"
        error={errors?.title}
      />

      <TextInput
        label={RESULTS_MAIN_FORM.DETAILS.INPUTS.HEADLINE.LABEL}
        description={RESULTS_MAIN_FORM.DETAILS.INPUTS.HEADLINE.DESCRIPTION}
        descriptionSecondary={RESULTS_MAIN_FORM.DETAILS.INPUTS.HEADLINE.DESCRIPTION_SECONDARY}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.DETAILS.INPUTS.HEADLINE.NAME, value)}
        textValue={resultInfo.nick_name || ''}
        maxLength={RESULTS_MAIN_FORM.DETAILS.INPUTS.HEADLINE.COUNT}
        theme="form"
        error={errors?.nick_name}
      />

      <TextArea
        label={RESULTS_MAIN_FORM.DETAILS.INPUTS.PURPOSE.LABEL}
        description2={RESULTS_MAIN_FORM.DETAILS.INPUTS.PURPOSE.DESCRIPTION_SECONDARY}
        descriptionList={RESULTS_MAIN_FORM.DETAILS.INPUTS.PURPOSE.LIST}
        textValue={resultInfo.purpose || ''}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.DETAILS.INPUTS.PURPOSE.NAME, value)}
        maxLength={RESULTS_MAIN_FORM.DETAILS.INPUTS.PURPOSE.COUNT}
        error={errors?.purpose}
        theme="form"
      />

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.COMPLETED.TITLE}
          margin="xsmall"
        />
        <Checkbox
          label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.COMPLETED.LABEL}
          isChecked={resultInfo.is_completed || false}
          onChange={(checked) => checkboxInputHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.COMPLETED.NAME, checked, RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.COMPLETED.NAME)}
          textTransform="none"
        />
      </div>

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.DETAILS.INPUTS.HEALTH_CATEGORIES.LABEL}
          margin="xsmall"
          isRequired
        />
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {RESULTS_MAIN_FORM.DETAILS.INPUTS.HEALTH_CATEGORIES.DESCRIPTION}
        </TextP>
        {/* //TODO ERROR MESSAGE */}
        <HealthResearchAreas
          categoriesMap={categoriesMap}
          selectedResearchAreas={selectedHealthCategories}
          handleChange={setHealthCategoriesHandler}
          forceChange={forceChange}
          isReseacher
          error={errors?.healthCategory}
        />
      </div>
    </Styled.FormChildrenWrapper>
  </FormSection>
);
