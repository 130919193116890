import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RESULTS_TYPE_MODAL } from './const';
import { RadioInput } from '../../../components/RadioInput';
import Button from '../../../components/Button';
import * as Styled from './styles';

type ResultsTypeModalPropTypes = {
  radioHandler: (option: string) => void
  radioValue?: string
  closeHandler: () => void
  submitHandler: () => void
  resultsType?: string
}

export const ResultsTypeModal = ({
  radioHandler,
  radioValue,
  closeHandler,
  submitHandler,
  resultsType,
}: ResultsTypeModalPropTypes) => (
  <div>
    <Styled.ModalTitleStyled>
      <div />
      <p>{RESULTS_TYPE_MODAL.TITLE}</p>
      <Button
        id="close"
        onClick={closeHandler}
        theme="plain"
      >
        <FontAwesomeIcon icon="xmark" size="lg" />
      </Button>
    </Styled.ModalTitleStyled>
    <Styled.ModalBodyStyled>
      <Styled.InputsLabelStyled>{RESULTS_TYPE_MODAL.LABEL}</Styled.InputsLabelStyled>
      <div>
        {RESULTS_TYPE_MODAL.OPTIONS.map((option) => (
          <Styled.RadioWrapper key={option.value}>
            <RadioInput
              onChange={() => radioHandler(option.value)}
              name={option.value}
              id={option.value}
              value={option.value}
              checked={radioValue === option.value}
            />
            <Styled.LabelStyled htmlFor={option.value}>
              {option.label}
            </Styled.LabelStyled>
          </Styled.RadioWrapper>
        ))}
      </div>

      <Styled.ButtonWrapper>
        <Button
          id={RESULTS_TYPE_MODAL.BUTTONS.CANCEL}
          onClick={closeHandler}
          theme="outlineTertiary"
        >
          {RESULTS_TYPE_MODAL.BUTTONS.CANCEL}
        </Button>
        <Button
          id={RESULTS_TYPE_MODAL.BUTTONS.CONTINUE}
          onClick={submitHandler}
          theme="primary"
          disabled={!resultsType}
        >
          {RESULTS_TYPE_MODAL.BUTTONS.CONTINUE}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.ModalBodyStyled>
  </div>
);
