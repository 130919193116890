import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type SubTitleWrapperProps = {
  readonly margin?: string
  readonly isDisabled?: boolean
}

type TextStyledPropTypes = {
  readonly unbold: boolean
}

export const SubTitleWrapper = styled.div<SubTitleWrapperProps>`
  margin: ${({ margin }) => {
    switch (margin) {
      case 'small':
        return '0 0 0.875rem 0';
      case 'xsmall':
        return '0 0 0.5rem 0';

      default:
        return '1rem 0';
    }
  }};
  display: flex;
  line-height: 1.5rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${({ isDisabled }) => (isDisabled ? colours.colour35 : '')};
`;

export const LinkImageStyled = styled.img`
  margin: 0 0.25rem;
`;

export const LinkWrapper = styled.span`
  align-items: center;
  display: inline-flex;
`;

export const TextStyled = styled.p<TextStyledPropTypes>`
  margin-left: 0.02rem;
  ${({ unbold }) => (unbold ? fonts.font1.H18 : fonts.font1.H18B)};
`;
