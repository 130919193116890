import styled from 'styled-components';
import toRem from '../../../../utils/toRem';
import { colours, fonts } from '../../../../styles/theme';

type FormDescriptionStylesTypes = {
  readonly theme?: 'secondary'
  readonly isDisabled?: boolean
}

export const FormChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(40)};
`;

export const FormDescriptionStyles = styled.p<FormDescriptionStylesTypes>`
  ${fonts.font1.H14};
  color: ${({ isDisabled }) => (isDisabled ? colours.colour35 : colours.colour36)};
  margin-bottom: ${({ theme }) => (theme === 'secondary' ? toRem(24) : toRem(16))};
`;

export const ButtonChildrenWrapper = styled.div`
  display: flex;
  gap: ${toRem(8)};
  align-items: center;
`;

export const InputsWrapper = styled.div`
  margin-bottom: ${toRem(24)};
`;

export const CheckboxWrapper = styled.div`
  margin: 0.875rem 0 0.875rem 1rem;
`;

export const CheckboxGroupWrapper = styled.div`
margin-top: ${toRem(24)};
`;

export const InputGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: ${toRem(32)};
  align-items: end;
`;
