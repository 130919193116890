import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import environment from '../config/environment';
import { userInitial } from '../utils/initialValues';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';
import { RESEARCHER_DASHBOARD, TABS } from '../pages/Dashboard/ResearcherDashboard/const';
import { sortByHandler } from '../utils';

export type ProjectResultType = {
  id: string,
  type: string,
  status: string
  recruitment_end: string
  title: string
};

export const useDashboard = () => {
  const navigate = useNavigate();

  const { getCookie, deleteCookie } = useCookies();
  const [user, setUser] = useState<IUser>(userInitial);
  const { getProjects, getSiteVolunteers } = useAPI();

  const [tableDataAll, setTableDataAll] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [siteVolunteerData, setSiteVolunteerData] = useState([]);
  const [researcherTab, setResearcherTab] = useState(RESEARCHER_DASHBOARD.TABS[0]);

  const [searchValue, setSearchValue] = useState('');
  const [resultSearchValue, setResultSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resultsType, setResultsType] = useState<string>();
  const [selectStudyModal, setSelectStudyModel] = useState(false);
  const [projectResultIndex, setProjectResultIndex] = useState<ProjectResultType[]>([]);

  const [projectResultFilter, setProjectResultFilter] = useState<ProjectResultType[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>();
  const [sortBy, setSortBy] = useState('title');
  const [sortDirection, setSortDirection] = useState('asc');

  const setModalIsOpenHandler = () => setModalIsOpen(!modalIsOpen);

  const setResultsTypeHandler = (option: string) => setResultsType(option);
  const setSelectStudyModelHandler = () => setSelectStudyModel(false);

  const getTableData = async () => {
    const projects = await getProjects(user.type);
    if (!projects.error) {
      setTableData(projects);
      setTableDataAll(projects);
      setIsFetching(false);
    }
  };

  const getSiteVolunteerData = async () => {
    const siteVolunteers = await getSiteVolunteers();
    if (siteVolunteers.error) {
      setSiteVolunteerData([]);
    } else {
      setSiteVolunteerData(siteVolunteers);
    }
  };

  const setResearcherTabHandler = (tab: { label: string, value: string }) => {
    setResearcherTab(tab);
    if (siteVolunteerData.length === 0 && tab.value === TABS.SITE_VOLUNTEERS) {
      getSiteVolunteerData();
    }
  };

  const setSearchValueHandler = (value: string) => setSearchValue(value);
  const setresultSearchValueHandler = (value: string) => {
    if (value === resultSearchValue) return;
    setResultSearchValue(value);
  };

  const searchProjectHandler = () => {
    if (searchValue === '') {
      setTableData(tableDataAll);
      return;
    }
    let projects = [...tableDataAll];
    projects = projects.filter((data: { nick_name: string; }) => data.nick_name?.toLowerCase().includes(searchValue.toLowerCase()));

    setTableData(projects);
  };

  const projectResultSearchHandler = () => {
    if (resultSearchValue === '') setProjectResultFilter(projectResultIndex);
    if (resultSearchValue !== '') {
      let projects: ProjectResultType[] = [...projectResultIndex];
      projects = projects.filter((data: { title: string; }) => data.title?.toLowerCase().includes(resultSearchValue.toLowerCase()));

      setProjectResultFilter(projects);
    }
  };

  const getResultLessProjectsHanndler = async () => {
    const projects = await getProjects('noResults');
    setProjectResultIndex(projects);
    setSelectStudyModel(true);
  };

  const addResultsSubmit = () => {
    if (resultsType === 'standalone') {
      navigate('/result/standalone/results-form');
    }
    getResultLessProjectsHanndler();
  };

  const sortHandler = (sortValue: string) => {
    if (sortBy === sortValue) {
      const sortDirectionValue = sortDirection === 'asc' ? 'desc' : 'asc';
      const results = sortByHandler(projectResultFilter, sortValue, sortDirectionValue);
      setSortDirection(sortDirectionValue);
      setProjectResultFilter(results);
    } else {
      const results = sortByHandler(projectResultFilter, sortValue, 'asc');
      setSortBy(sortValue);
      setSortDirection('asc');
      setProjectResultFilter(results);
    }
  };

  const setSelectedProjectHandler = (id: string) => setSelectedProject(id);

  const navigateToNewResultPage = () => {
    const projectInfo = projectResultIndex.find((project) => (project.id === selectedProject));
    if (!projectInfo) return;

    const path = projectInfo.type === 'ppo' ? `/project/ppo/${projectInfo.id}/results-form` : `/project/study/${projectInfo.id}/results-form`;

    navigate(path);
  };

  useEffect(() => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      setUser(decodedUser);
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  }, []);

  useEffect(() => {
    if (!user.id || !user.type || user.type === 'volunteer') return;
    getTableData();
  }, [JSON.stringify(user)]);

  useEffect(() => {
    if (user.type === 'researcher') searchProjectHandler();
  }, [searchValue]);

  useEffect(() => {
    if (!isFetching) setIsLoading(false);
  }, [isFetching]);

  useEffect(() => {
    if (modalIsOpen) return;

    setSelectStudyModel(false);
  }, [modalIsOpen]);

  useEffect(() => {
    setProjectResultFilter(projectResultIndex);
  }, [projectResultIndex]);

  useEffect(() => {
    projectResultSearchHandler();
  }, [resultSearchValue]);

  return {
    user,
    tableData,
    researcherTab,
    setResearcherTab: setResearcherTabHandler,
    siteVolunteerData,
    searchValue,
    setSearchValueHandler,
    isLoading,
    modalIsOpen,
    setModalIsOpenHandler,

    resultsType,
    setResultsTypeHandler,

    addResultsSubmit,
    selectStudyModal,

    resultSearchValue,
    setresultSearchValueHandler,
    setSelectStudyModelHandler,
    projectResultFilter,

    selectedProject,
    setSelectedProjectHandler,

    sortBy,
    sortDirection,
    sortHandler,

    navigateToNewResultPage,
  };
};
