import React from 'react';
import * as Styled from '../styles';
import FormSection from '../../../../../components/FormSelection';
import { TextArea } from '../../../../../components/TextArea.tsx';
import { RESULTS_MAIN_FORM } from '../const';
import { CommentSectionPropTypes } from '../types';

export const CommentSection = ({
  resultInfo,
  updateResultInfoHandler,
}: CommentSectionPropTypes) => (
  <FormSection noHeader>
    <Styled.FormChildrenWrapper>
      <TextArea
        label={RESULTS_MAIN_FORM.COMMENTS.INPUTS.COMMENT.LABEL}
        description={RESULTS_MAIN_FORM.COMMENTS.INPUTS.COMMENT.DESCRIPTION}
        description2={[RESULTS_MAIN_FORM.COMMENTS.INPUTS.COMMENT.DESCRIPTION_SECONDARY]}
        textValue={resultInfo.comments || ''}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.COMMENTS.INPUTS.COMMENT.NAME, value)}
        isNotPublic
        isRequired={false}
        theme="form"
      />
    </Styled.FormChildrenWrapper>
  </FormSection>
);
