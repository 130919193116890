export const RESULT_SIDE_MENU = {
  BUTTON: 'Submit for Review',
};

export const STATUS_ICON: Record<string, string> = {
  pending_approval: 'hourglass',
  approved: 'check-circle',
};

export const RESULT_STATUS: { [key: string]: string } = Object.freeze({
  pending_approval: 'Your result is under review',
  approved: 'Your result are Approved',
});
