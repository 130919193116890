export const STAND_ALONE_RESULT_CONST = {
  TABS: [
    { LABEL: 'Study Purpose', URL: 'studyPurpose' },
    { LABEL: 'Study Results', URL: 'studyResults' },
    { LABEL: 'Patient Partner Engagement Strategies', URL: 'engagementStraegies' },
    { LABEL: 'Study Limitations', URL: 'studyLimitaions' },
    { LABEL: 'Additional Information / Resources', URL: 'resources' },
  ],

  TAB_ID: {
    PURPOSE: 'studyPurpose',
    RESULTS: 'studyResults',
    ENGAGEMENT: 'engagementStraegies',
    LIMITATIONS: 'studyLimitaions',
    RESOURCES: 'resources',
    HEALTH_CATEGORY: 'healthCategories',
  },

  SESSION_HEADERS: {
    PURPOSE: 'Study Purpose',
    RESULTS: 'Study Results',
    ENGAGEMENT: 'Patient Partner Engagement Strategies',
    LIMITATIONS: 'Study Limitations',
    RESOURCES: 'Additional Information / Resources',
    HEALTH_CATEGORY: 'Health Categories',
    INFORMATION: 'Study Information',
    LOCATION: 'Study Location',
    PI: 'Principal Investigator',
    CONTACTS: 'Primary Contacts',
  },

  LABEL: {
    PARTICIPATION_TYPE: 'Collaborating Organizations',
    COLLABORATING_ORGANIZATION: 'Collaborating Organizations',
    BC_AFFILIATION: 'BC SUPPORT Unit Affiliation',
  },
};
