export const SOURCE_TYPES = {
  APP: 'app',
  WEBSITE: 'website',
  EMAIL: 'email',
  OTHER: 'other',
};

export const PHONE_NUMBER_REGEX = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const POSTAL_CODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
