import styled from 'styled-components';
import { fonts, colours } from '../../styles/theme';

type StyledCheckboxProps = {
  readonly isChecked: boolean;
  readonly theme?: string
  readonly disabled?: boolean
};

type LabelStyledProps = {
  readonly textTransform?: string;
  readonly theme?: 'secondary'
  readonly disabled?: boolean
};

type InputStyledProps = {
  readonly isDisabled?: boolean
}

export const CheckboxWrapper = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;

  &:hover {
    div {
      border: 1px solid  ${({ isChecked }) => (isChecked ? 'transparent' : colours.colour16)};
    }
    label {
      color: ${colours.colour16};
    }
  }
  ${({ disabled, isChecked }) => (
    disabled ? `
    cursor: not-allowed;
    div {
      border: 1px solid ${isChecked ? 'transparent' : colours.colour35};
    }
    label {
      color: ${colours.colour35};
    }
  ` : '')}
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  ${({ theme }) => (theme === 'secondary' ? fonts.font1.H14N : fonts.font1.H14)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-transform: ${({ textTransform }) => textTransform};
  color: ${colours.colour6};
  line-height: 1.1rem;
  margin-left: 0.5rem;
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${fonts.font1.H12};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  margin-top: 0.875rem;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxStyled = styled.div<StyledCheckboxProps>`
  display: inline-block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 150ms;
  
  width: ${({ theme }) => {
    switch (theme) {
      case 'small': {
        return '14px';
      }
      default:
        return '17px';
    }
  }};
  min-width: ${({ theme }) => {
    switch (theme) {
      case 'small': {
        return '14px';
      }
      default:
        return '17px';
    }
  }};

  height: ${({ theme }) => {
    switch (theme) {
      case 'small': {
        return '14px';
      }
      default:
        return '17px';
    }
  }};

  background: ${({ isChecked, theme }) => {
    if (isChecked) {
      switch (theme) {
        case 'small':
          return colours.colour5;
        default:
          return colours.colour2;
      }
    } else {
      return colours.colour4;
    }
  }};

  border: 1px solid ${({ isChecked, theme }) => {
    if (isChecked) {
      switch (theme) {
        case 'small':
          return colours.colour5;
        default:
          return colours.colour2;
      }
    } else {
      return colours.colour5;
    }
  }};

  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'small':
        return '3px';
      default:
        return '3px';
    }
  }};

  span {
    position: absolute;
    transition: all 150ms;
    left: ${({ theme }) => {
    switch (theme) {
      case 'small':
        return '4px';
      default:
        return '4px';
    }
  }};
    top: 0.5px;
    width: ${({ theme }) => {
    switch (theme) {
      case 'small':
        return '5px';
      default:
        return '7px';
    }
  }};

    height: ${({ theme }) => {
    switch (theme) {
      case 'small':
        return '8px';
      default:
        return '10px';
    }
  }};;
    border: solid  ${({ isChecked, theme }) => {
    if (isChecked) {
      switch (theme) {
        case 'small':
          return colours.colour4;
        default:
          return colours.colour5;
      }
    } else {
      return 'transparent';
    }
  }};

    border-width: ${({ theme }) => {
    switch (theme) {
      case 'small':
        return '0 2px 2px 0';
      default:
        return '0 3px 3px 0';
    }
  }};
        
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } 
`;
