import React from 'react';
import * as Styled from '../styles';
import FormSection from '../../../../../components/FormSelection';
import { TextInput } from '../../../../../components';
import { AdditionalInformationSectionPropTypes } from '../types';
import { RESULTS_MAIN_FORM } from '../const';
import { FileInput } from '../../../../../components/FileInput';

export const AdditionalInformationSection = ({
  resultInfo,
  updateResultInfoHandler,
  fileHandler,
  removeFileHandler,
  currentFile,
  errors,
}: AdditionalInformationSectionPropTypes) => (
  <FormSection
    title={RESULTS_MAIN_FORM.INFORMATION.TITLE}
    subText={RESULTS_MAIN_FORM.INFORMATION.DESCRIPTION}
  >
    <Styled.FormChildrenWrapper>
      <TextInput
        label={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_URL.LABEL}
        description={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_URL.DESCRIPTION}
        placeholder={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_URL.PLACEHOLDER}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_URL.NAME, value)}
        textValue={resultInfo.resource_website_url || ''}
        theme="form"
        isRequired={false}
        error={errors?.resource_website_url}
      />

      <TextInput
        label={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_NAME.LABEL}
        description={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_NAME.DESCRIPTION}
        descriptionSecondary={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_NAME.DESCRIPTION2}
        placeholder={RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_NAME.PLACEHOLDER}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.INFORMATION.INPUTS.WEBSITE_NAME.NAME, value)}
        textValue={resultInfo.resource_website_name || ''}
        theme="form"
        isRequired={false}
        error={errors?.resource_website_name}
      />

      <FileInput
        label={RESULTS_MAIN_FORM.INFORMATION.INPUTS.DOCUMENT.LABEL}
        description={RESULTS_MAIN_FORM.INFORMATION.INPUTS.DOCUMENT.DESCRIPTION}
        handleChange={fileHandler}
        currentFile={currentFile}
        fileName={resultInfo.resource_file_name}
        handleRemoveFile={removeFileHandler}
        // handleGetSignedURl={handleGetSignedURl}
        field={RESULTS_MAIN_FORM.INFORMATION.INPUTS.DOCUMENT.NAME}
        isRequired={false}
        error={errors?.resource_file_name}
      />
    </Styled.FormChildrenWrapper>
  </FormSection>
);
