export const sortByHandler = (list: any[], sortBy: string, sortDir: 'asc' | 'desc') => list.sort((a: any, b: any) => {
  if (typeof a[sortBy] === 'string') {
    if (sortDir === 'asc') {
      if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return -1;
      }
      if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
      return -1;
    }
    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  if (sortDir === 'asc') {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] < b[sortBy]) {
      return 1;
    }
    return 0;
  }

  if (a[sortBy] > b[sortBy]) {
    return -1;
  }
  if (a[sortBy] > b[sortBy]) {
    return 1;
  }
  return 0;
});
