import environment from '../config/environment';
import { useCookies } from './useCookies';

export const useAPI = () => {
  const { getCookie, deleteCookie } = useCookies();

  const login = async (userInfo: inputLogin) => {
    const response = await fetch(`${environment.api.baseUrl}/auth/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
    const token = await response.json();
    return token;
  };

  const signUp = async (userInfo: volunteerInfo | researcherInfo) => {
    const response = await fetch(`${environment.api.baseUrl}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
    const user = await response.json();
    if (user.error) {
      throw user.error;
    }
    return user;
  };

  const checkUserExist = async (email: string) => {
    const response = await fetch(`${environment.api.baseUrl}/check-email/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    if (result.error) throw result.error;
  };

  const verifyEmailCode = async (input: inputVerification) => {
    const response = await fetch(`${environment.api.baseUrl}/auth/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(input),
    });
    const confirmedUser = await response.json();
    if (confirmedUser.error) {
      throw new Error(confirmedUser.error);
    }
    return confirmedUser;
  };

  const resendVerificationEmail = async (email: string) => {
    const response = await fetch(`${environment.api.baseUrl}/auth/resendVerification/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const confirmedUser = await response.json();
    if (confirmedUser.error) {
      throw new Error(confirmedUser.error);
    }
    return confirmedUser;
  };

  const authChallange = async (challenge: string) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/auth/authChallenge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie('authChallenge') || '',
        },
        body: JSON.stringify({ challenge }),
      });
      const user = await response.json();
      if (user.error) throw new Error(user.error);
      if (user.expiredAuth) { deleteCookie(environment.app.cookieName); }
      return user;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/auth/forgotPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const user = await response.json();
      if (user.error) throw new Error(user.error);
      return user;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const resetForgottenPassword = async (body: { code: string, password: string, email: string }) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/auth/resetForgottenPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      });
      const user = await response.json();
      if (user.error) throw new Error(user.error);
      return user;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const changePassword = async (body: { newPassword: string, oldPassword: string }) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/auth/changePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName),
          cognitoToken: getCookie(environment.app.cognitoName),
        },
        body: JSON.stringify(body),
      });
      const user = await response.json();
      if (user.error) throw new Error(user.error);
      if (user.expiredAuth) { deleteCookie(environment.app.cookieName); }
      return user;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getUniversities = async () => {
    const response = await fetch(`${environment.api.baseUrl}/university`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const universities = await response.json();
    return universities;
  };

  const getInterested = async (id: string) => {
    const response = await fetch(`${environment.api.baseUrl}/ProjectInterests/interested/${id}`, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const interested = await response.json();
    if (interested.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return interested;
  };

  const getHealthAuthorities = async () => {
    const response = await fetch(`${environment.api.baseUrl}/healthAuthorityRegions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const healthAuthorityRegion = await response.json();
    return healthAuthorityRegion;
  };

  const getAuthorities = async () => {
    const response = await fetch(`${environment.api.baseUrl}/healthAuthorities`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const healthAuthority = await response.json();
    return healthAuthority;
  };

  const getHospitals = async () => {
    const response = await fetch(`${environment.api.baseUrl}/hospitals`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const hospitals = await response.json();
    return hospitals;
  };

  const getHealthCategory = async () => {
    const response = await fetch(`${environment.api.baseUrl}/healthCategories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const healthCategory = await response.json();
    return healthCategory;
  };

  const getPrincipalInvestigatorInfo = async (piId: string) => {
    const response = await fetch(`${environment.api.baseUrl}/user/${piId}/researcher`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const user = await response.json();
    return user[0];
  };

  const getProject = async (projectId: number, type: string, source?: string) => {
    const url = `${environment.api.baseUrl}/projects/${projectId}/${type}${source ? `?source=${source}` : ''}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const project = await response.json();
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const getProjects = async (userType: string) => {
    if (userType === 'researcher') {
      const response = await fetch(`${environment.api.baseUrl}/projects`, {
        method: 'GET',
        headers: {
          Auth: getCookie(environment.app.cookieName),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      const projects = await response.json();
      if (projects.expiredAuth) { deleteCookie(environment.app.cookieName); }
      return projects;
    }

    if (userType === 'volunteer') {
      const response = await fetch(`${environment.api.baseUrl}/projectMatches`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName),
        },
      });

      const projects = await response.json();
      if (projects.expiredAuth) { deleteCookie(environment.app.cookieName); }
      return projects;
    }

    if (userType === 'noResults') {
      const response = await fetch(`${environment.api.baseUrl}/projects/results`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName),
        },
      });

      const projects = await response.json();
      if (projects.expiredAuth) { deleteCookie(environment.app.cookieName); }
      return projects;
    }

    return [];
  };

  const createProject = async (
    projectDetails: FormData,
  ) => {
    const response = await fetch(`${environment.api.baseUrl}/projects`, {
      method: 'POST',
      headers: {
        Auth: getCookie(environment.app.cookieName),
      },
      body: projectDetails,
    });
    const project = await response.json();
    if (project.error) {
      throw new Error(project.error);
    }
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const updateProject = async (
    projectDetails: FormData,
  ) => {
    const response = await fetch(`${environment.api.baseUrl}/projects`, {
      method: 'PATCH',
      headers: {
        Auth: getCookie(environment.app.cookieName),
      },
      body: projectDetails,
    });
    const project = await response.json();
    if (project.error) {
      throw new Error(project.error);
    }
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const deleteDraftProject = async (projectId: number) => {
    const response = await fetch(`${environment.api.baseUrl}/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const project = await response.json();
    if (project.error) {
      throw new Error(project.error);
    }
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const updateUser = async (userInfo: any) => {
    const user = userInfo;
    const response = await fetch(`${environment.api.baseUrl}/user/update`, {
      method: 'PATCH',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user),
    });
    const token = await response.json();
    if (token.error) {
      throw new Error(token.error);
    }
    if (token.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return token;
  };

  const getProjectInterest = async () => {
    const response = await fetch(`${environment.api.baseUrl}/ProjectInterests/interested`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const projectInterest = await response.json();
    if (projectInterest.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return projectInterest;
  };

  const createProjectInterest = async (interestDetails: any) => {
    const response = await fetch(`${environment.api.baseUrl}/ProjectInterests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
      body: JSON.stringify(interestDetails)
      ,
    });
    const project = await response.json();
    if (project.error) {
      throw new Error(project.error);
    }
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const updateProjectInterest = async (interestDetails: any) => {
    const response = await fetch(`${environment.api.baseUrl}/ProjectInterests`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
      body: JSON.stringify(interestDetails),
    });
    const project = await response.json();
    if (project.error) {
      throw new Error(project.error);
    }
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const updateBulkProjectInterest = async (interestDetails: any) => {
    const response = await fetch(`${environment.api.baseUrl}/ProjectInterests/project`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
      body: JSON.stringify(interestDetails),
    });
    const project = await response.json();
    if (project.error) {
      throw new Error(project.error);
    }
    if (project.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return project;
  };

  const getUser = async (id: number, type: string) => {
    const response = await fetch(`${environment.api.baseUrl}/user/${id}/${type}`, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const user = await response.json();
    if (user.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return user;
  };

  const getHealthRegions = async () => {
    const response = await fetch(`${environment.api.baseUrl}/healthAuthorityRegions`, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const regions = await response.json();
    if (regions.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return regions;
  };

  const getArchivedInterest = async () => {
    const response = await fetch(`${environment.api.baseUrl}/ProjectInterests/archived`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const projects = await response.json();
    if (projects.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return projects || [];
  };

  const publicCreateProjectInterest = async (projectId: string, userEmail: string, interest = 'not-interest', source = 'app') => {
    const response = await fetch(`${environment.api.baseUrl}/Public/ProjectInterests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
      body: JSON.stringify({
        projectId, userEmail, interest, created_at: new Date(), source,
      }),
    });
    const projectInterest = await response.json();
    return projectInterest;
  };

  const getSiteVolunteers = async () => {
    const response = await fetch(`${environment.api.baseUrl}/user/site-volunteers`, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const users = await response.json();
    if (users.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return users;
  };

  const publicUpdate = async (path: string, body: Record<string, any>) => {
    const response = await fetch(`${environment.api.baseUrl}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
      body: JSON.stringify({
        ...body,
      }),
    });
    const results = await response.json();
    return results;
  };

  const getSignedURL = async (projectId: number, field: string) => {
    const response = await fetch(`${environment.api.baseUrl}/projects/signedUrl/${projectId}/${field}`, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const signedUrl = await response.json();
    if (signedUrl.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return signedUrl;
  };

  const getResultStandAlone = async (resultId: number) => {
    const response = await fetch(`${environment.api.baseUrl}/resultStandAlone/${resultId}`, {
      method: 'GET',
      headers: {
        Auth: getCookie(environment.app.cookieName),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const result = await response.json();
    if (result.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return result;
  };

  return {
    login,
    signUp,
    verifyEmailCode,
    getHealthAuthorities,
    getHospitals,
    getHealthCategory,
    getProject,
    getPrincipalInvestigatorInfo,
    getProjects,
    createProject,
    updateProject,
    deleteDraftProject,
    createProjectInterest,
    updateProjectInterest,
    getArchivedInterest,
    getProjectInterest,
    updateUser,
    getHealthRegions,
    getUser,
    getUniversities,
    getAuthorities,
    getInterested,
    authChallange,
    forgotPassword,
    resetForgottenPassword,
    changePassword,
    checkUserExist,
    publicCreateProjectInterest,
    resendVerificationEmail,
    getSiteVolunteers,
    publicUpdate,
    getSignedURL,
    updateBulkProjectInterest,
    getResultStandAlone,
  };
};
