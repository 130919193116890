import React from 'react';
import * as Styled from './styles';
import { ResultTabsPropTypes } from './types';

export const ResultTabs = ({
  currentTab,
  setCurrentTab,
  tabs,
}: ResultTabsPropTypes) => (
  <Styled.TabWrapper>
    {tabs.map((tab) => (
      <Styled.LinkStyled
        selected={currentTab === tab.URL}
        key={tab.LABEL}
        id={`link-${tab.URL}`}
        onClick={() => setCurrentTab(tab.URL)}
      >
        {tab.LABEL}
      </Styled.LinkStyled>
    ))}
  </Styled.TabWrapper>
);
