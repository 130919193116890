import * as Yup from 'yup';
import { VALIDATION_CONST } from './const';
import { PHONE_NUMBER_REGEX, POSTAL_CODE_REGEX } from '../../../utils';

export const emailValidation = Yup.object().shape({
  email: Yup.string()
    .typeError(VALIDATION_CONST.EMAIL.TYPE)
    .email(VALIDATION_CONST.EMAIL.TYPE)
    .required(VALIDATION_CONST.EMAIL.REQUIRED),
});

export const createStandeAloneResultValidations = Yup.object().shape({
  status: Yup.string()
    .typeError(VALIDATION_CONST.STATUS.TYPE)
    .required(VALIDATION_CONST.STATUS.REQUIRED),
  pi_id: Yup.number()
    .typeError(VALIDATION_CONST.PI_ID.TYPE)
    .required(VALIDATION_CONST.PI_ID.REQUIRED),
  title: Yup.string()
    .typeError(VALIDATION_CONST.TITLE.TYPE)
    .required(VALIDATION_CONST.TITLE.REQUIRED),
  nick_name: Yup.string()
    .typeError(VALIDATION_CONST.NICK_NAME.TYPE)
    .required(VALIDATION_CONST.NICK_NAME.REQUIRED),
  purpose: Yup.string()
    .typeError(VALIDATION_CONST.PROJECT_PURPOSE.TYPE)
    .required(VALIDATION_CONST.PROJECT_PURPOSE.REQUIRED),
  result_summary: Yup.string()
    .typeError(VALIDATION_CONST.RESULT_SUMMARY.TYPE)
    .required(VALIDATION_CONST.RESULT_SUMMARY.REQUIRED),
  study_limitations: Yup.string()
    .typeError(VALIDATION_CONST.STUDY_LIMITATIONS.TYPE)
    .required(VALIDATION_CONST.STUDY_LIMITATIONS.REQUIRED),
  is_online: Yup.boolean()
    .typeError(VALIDATION_CONST.IS_ONLINE.TYPE)
    .required(VALIDATION_CONST.IS_ONLINE.REQUIRED),
  hospital_id: Yup.string()
    .nullable()
    .typeError(VALIDATION_CONST.HOSPITAL.TYPE)
    .when('is_online', {
      is: true,
      then: Yup.string().transform(() => undefined),
      otherwise: Yup.string(),
    }),

  building: Yup.string()
    .nullable()
    .typeError(VALIDATION_CONST.BUILDING.TYPE)
    .when('is_online', {
      is: true,
      then: Yup.string().transform(() => undefined),
      otherwise: Yup.string(),
    }),

  department: Yup.string()
    .nullable()
    .typeError(VALIDATION_CONST.DEPARTMENT.TYPE)
    .when('is_online', {
      is: true,
      then: Yup.string().transform(() => undefined),
      otherwise: Yup.string(),
    }),

  street: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : originalValue))
    .when('is_online', {
      is: false,
      then: Yup.string()
        .typeError(VALIDATION_CONST.STREET.TYPE)
        .required(VALIDATION_CONST.STREET.REQUIRED),
      otherwise: Yup.string()
        .notRequired()
        .transform(() => undefined),
    }),

  city: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : originalValue))
    .when('is_online', {
      is: false,
      then: Yup.string()
        .typeError(VALIDATION_CONST.CITY.TYPE)
        .required(VALIDATION_CONST.CITY.REQUIRED),
      otherwise: Yup.string()
        .notRequired()
        .transform(() => undefined),
    }),

  postal_code: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : originalValue))
    .when('is_online', {
      is: false,
      then: Yup.string()
        .typeError(VALIDATION_CONST.POSTAL_CODE.TYPE)
        .matches(POSTAL_CODE_REGEX, VALIDATION_CONST.POSTAL_CODE.MATCH)
        .required(VALIDATION_CONST.POSTAL_CODE.REQUIRED),
      otherwise: Yup.string()
        .notRequired()
        .transform(() => undefined),
    }),
  is_completed: Yup.boolean()
    .typeError(VALIDATION_CONST.IS_COMPLETED.TYPE)
    .required(VALIDATION_CONST.IS_COMPLETED.REQUIRED),
  is_por: Yup.boolean()
    .typeError(VALIDATION_CONST.IS_POR.TYPE)
    .required(VALIDATION_CONST.IS_POR.REQUIRED),
  is_ppo: Yup.boolean()
    .typeError(VALIDATION_CONST.IS_PPO.TYPE)
    .required(VALIDATION_CONST.IS_PPO.REQUIRED),
  is_bc_support_unit_affiliation: Yup.boolean()
    .typeError(VALIDATION_CONST.IS_BC_SUPPORT_UNIT.TYPE)
    .required(VALIDATION_CONST.IS_BC_SUPPORT_UNIT.REQUIRED),
  regional_centers: Yup.array().when('is_bc_support_unit_affiliation', {
    is: true,
    then: Yup.array()
      .min(1, VALIDATION_CONST.REGIONAL_CENTRE.MIN)
      .required(VALIDATION_CONST.REGIONAL_CENTRE.REQUIRED),
    otherwise: Yup.array().notRequired().nullable(),
  }),
  resource_website_name: Yup.string()
    .typeError(VALIDATION_CONST.RESOURCE_WEBSITE_NAME.TYPE),
  resource_website_url: Yup.string()
    .typeError(VALIDATION_CONST.RESOURCE_WEBSITE_URL.TYPE),
  resource_file_name: Yup.string()
    .typeError(VALIDATION_CONST.RESOURCE_FILE_NAME.TYPE),
  comments: Yup.string()
    .typeError(VALIDATION_CONST.COMMENTS.TYPE),
  contact1_name: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT1_NAME.TYPE)
    .required(VALIDATION_CONST.CONTACT1_NAME.REQUIRED),
  contact1_position: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT1_POSITION.TYPE)
    .required(VALIDATION_CONST.CONTACT1_POSITION.REQUIRED),
  contact1_email: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT1_EMAIL.TYPE)
    .required(VALIDATION_CONST.CONTACT1_EMAIL.REQUIRED),
  contact1_phone: Yup.string()
    .matches(
      PHONE_NUMBER_REGEX,
      VALIDATION_CONST.PHONE_NUMBER.MATCH,
    )
    .min(10, VALIDATION_CONST.PHONE_NUMBER.MIN)
    .max(10, VALIDATION_CONST.PHONE_NUMBER.MAX)
    .nullable(),
  contact1_phone_extension: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT1_PHONE_EXTENSION.TYPE),
  contact2_name: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT2_NAME.TYPE),
  contact2_position: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT2_POSITION.TYPE),
  contact2_email: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT2_EMAIL.TYPE),
  contact2_phone: Yup.string()
    .matches(
      PHONE_NUMBER_REGEX,
      VALIDATION_CONST.PHONE_NUMBER.MATCH,
    )
    .min(10, VALIDATION_CONST.PHONE_NUMBER.MIN)
    .max(10, VALIDATION_CONST.PHONE_NUMBER.MAX)
    .nullable(),
  contact2_phone_extension: Yup.string()
    .typeError(VALIDATION_CONST.CONTACT2_PHONE_EXTENSION.TYPE),
  teamMembers: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number()
          .typeError(VALIDATION_CONST.TEAM_MEMBERS.TYPE)
          .required(VALIDATION_CONST.TEAM_MEMBERS.REQUIRED),
      }),
    )
    .min(1, VALIDATION_CONST.TEAM_MEMBERS.REQUIRED_VALID)
    .required(VALIDATION_CONST.TEAM_MEMBERS.REQUIRED_VALID),

  healthCategories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(VALIDATION_CONST.HEALTH_CATEGORIES.REQUIRED),
      }),
    )
    .min(1, VALIDATION_CONST.HEALTH_CATEGORIES.REQUIRED)
    .required(VALIDATION_CONST.HEALTH_CATEGORIES.REQUIRED),

  healthAuthorityRegions: Yup.array()
    .min(1, VALIDATION_CONST.HEALTH_REGIONS.REQUIRED)
    .required(VALIDATION_CONST.HEALTH_REGIONS.REQUIRED),
  pp_co_inverstigator: Yup.string()
    .typeError(VALIDATION_CONST.PP_CO_INVERSTIGATOR.TYPE),
  pp_affiliation_organization: Yup.string()
    .typeError(VALIDATION_CONST.PP_ORGANIZATION.TYPE),
  collaborating_organizations: Yup.string()
    .typeError(VALIDATION_CONST.COLLABORATING_ORGANIZATION.TYPE),
  collaborators: Yup.string()
    .typeError(VALIDATION_CONST.COLLABORATORS.TYPE),
  patient_partners_engagement_method: Yup.string().when(['is_por', 'is_ppo'], {
    is: (isPor: boolean, isPpo: boolean) => isPor || isPpo,
    then: Yup.string()
      .typeError(VALIDATION_CONST.ENGAGEMENT_METHOD.TYPE)
      .required(VALIDATION_CONST.ENGAGEMENT_METHOD.REQUIRED),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  patient_partners_engagement_number: Yup.number().when(['is_por', 'is_ppo'], {
    is: (isPor: boolean, isPpo: boolean) => isPor || isPpo,
    then: Yup.number()
      .typeError(VALIDATION_CONST.ENGAGEMENT_NUMBER.TYPE)
      .required(VALIDATION_CONST.ENGAGEMENT_NUMBER.REQUIRED),
    otherwise: Yup.number().notRequired().nullable(),
  }),
  created_at: Yup.date()
    .typeError(VALIDATION_CONST.CREATED_AT.TYPE)
    .required(VALIDATION_CONST.CREATED_AT.REQUIRED),
});
