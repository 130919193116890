/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import * as Styled from './styles';
import { MainDetailBodyPropTypes } from './types';
import { STAND_ALONE_RESULT_CONST } from '../../const';

export const MainDetailBody = ({
  result,
  sectionRefs,
}: MainDetailBodyPropTypes) => (
  <div>
    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.PURPOSE}
      ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.PURPOSE] = ref as HTMLDivElement)}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.PURPOSE}
      </Styled.TitleStyled>
      <p>{result.purpose}</p>
    </Styled.SectionStyled>

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.RESULTS}
      ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.RESULTS] = ref as HTMLDivElement)}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.RESULTS}
      </Styled.TitleStyled>
      <p>{result.result_summary}</p>
    </Styled.SectionStyled>

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.ENGAGEMENT}
      ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.ENGAGEMENT] = ref as HTMLDivElement)}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.ENGAGEMENT}
      </Styled.TitleStyled>
      <p>ENGAGEMENT</p>
    </Styled.SectionStyled>

    {result.study_limitations && (
      <Styled.SectionStyled
        id={STAND_ALONE_RESULT_CONST.TAB_ID.LIMITATIONS}
        ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.LIMITATIONS] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>
          {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.LIMITATIONS}
        </Styled.TitleStyled>
        <p>{result.study_limitations}</p>
      </Styled.SectionStyled>
    )}

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.RESOURCES}
      ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.RESOURCES] = ref as HTMLDivElement)}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.RESOURCES}
      </Styled.TitleStyled>
      <p>resouces</p>
    </Styled.SectionStyled>

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.HEALTH_CATEGORY}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.HEALTH_CATEGORY}
      </Styled.TitleStyled>
      <Styled.HealthCategoryWrapper>
        {result.health_categories.map((category) => (
          <p key={category}>{category}</p>
        ))}
      </Styled.HealthCategoryWrapper>
    </Styled.SectionStyled>
  </div>
);
