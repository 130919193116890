import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI, useScrollTo, useUser } from '../../../hooks';
import { STAND_ALONE_RESULT_CONST } from './const';

export const useDetailStandAloneResult = () => {
  const params: any = useParams();

  const { getResultStandAlone } = useAPI();

  const { getUserInfo, user } = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [resultInfo, setResultInfo] = useState<IStandAloneResultDetail>();
  const [currentTab, setCurrentTab] = useState(STAND_ALONE_RESULT_CONST.TABS[0].URL);

  const scrollHook = useScrollTo(STAND_ALONE_RESULT_CONST.TABS[0].URL, isLoading, currentTab);

  const setCurrentTabHandler = (url: string) => setCurrentTab(url);

  const getResults = async () => {
    const { id } = params;

    if (!id || id === undefined) throw new Error('Missing info');

    const { result } = await getResultStandAlone(id);
    setResultInfo(result);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfo();
    getResults();
  }, []);

  return {
    user,
    resultInfo,

    currentTab,
    setCurrentTabHandler,
    scrollHook,
  };
};
