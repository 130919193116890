import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from './styles';
import { EXISTING_STUDY_RESULTS } from './const';
import { TextInput } from '../../../components';
import Button from '../../../components/Button';
import { ProjectResultGrid } from '../ProjectResultGrid';
import { ProjectResultType } from '../../../hooks/useDashboard';

type ExistingStudyResultsPropTypes = {
  closeHandler: () => void
  backHandler: () => void
  searchValue: string
  searchHandler: (searchValue: string) => void
  projects: ProjectResultType[]
  sortHandler: (column: string) => void
  sortBy: string
  sortDirection: string
  selectedProject?: string
  setSelectedProjectHandler: (id: string) => void
  navigateToNewResultPage: () => void
}

export const ExistingStudyResults = ({
  closeHandler,
  backHandler,
  searchHandler,
  searchValue,
  projects,
  selectedProject,
  setSelectedProjectHandler,
  sortBy,
  sortDirection,
  sortHandler,
  navigateToNewResultPage,
}: ExistingStudyResultsPropTypes) => (
  <div>
    <Styled.ModalTitleStyled>
      <div />
      <p>{EXISTING_STUDY_RESULTS.TITLE}</p>
      <Button
        id="close"
        onClick={closeHandler}
        theme="plain"
      >
        <FontAwesomeIcon icon="xmark" size="lg" />
      </Button>
    </Styled.ModalTitleStyled>
    <Styled.ModalBodyStyled>
      <TextInput
        setTextValue={searchHandler}
        textValue={searchValue}
        placeholder={EXISTING_STUDY_RESULTS.SEARCH_BAR.PLACEHOLDER}
        theme="searchSecondary"
        isSearchBar
      />

      <ProjectResultGrid
        projects={projects}
        selectedProject={selectedProject}
        setSelectedProjectHandler={setSelectedProjectHandler}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sortHandler={sortHandler}
      />

      <Styled.ButtonWrapper>
        <Button
          id={EXISTING_STUDY_RESULTS.BUTTONS.BACK}
          onClick={backHandler}
          theme="outlineTertiary"
        >
          {EXISTING_STUDY_RESULTS.BUTTONS.BACK}
        </Button>
        <Button
          onClick={navigateToNewResultPage}
          id="add"
          disabled={!selectedProject}
        >
          {EXISTING_STUDY_RESULTS.LINK.ADD.LABEL}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.ModalBodyStyled>
  </div>
);
