export const STAND_ALONE_RESULTS = {
  HEADER: {
    title: 'Stand Alone Study Results Form',
    description: [
      'Use this form to publish the results of your completed research study on REACH BC. If your project is still in progress, you can share a summary of results to date.',
      'If you posted a patient partner opportunity and a research study on REACH BC for the same project, you only need to complete this form below.',
    ],
  },
  INFO_BOX: {
    TITLE: 'Learn more about posting a closed research study for sharing results.',
    DESCRIPTION: [
      'For additional resources like the Standard Operating Procedures (SOPs) and documents, please visit our Help Centre to learn more.',
    ],
    LINK: {
      label: 'Learn About Sharing Study Results',
      path: '/',
    },
    SECONDARY_LINK: {
      label: 'Visit Researcher Help Centre',
      path: '/',
    },
  },
};

export const VALIDATION_CONST = {
  EMAIL: {
    TYPE: 'Please enter a valid email address',
    REQUIRED: 'Please enter a valid email address',
  },
  TEAM_MEMBERS: {
    TYPE: 'Please enter a valid email address',
    REQUIRED: 'Please enter a valid email address',
    VALID: 'An account with this email address does not exist. For this account to be saved, they must have an account associated with this email.',
    REMOVE: 'A new Additional Team Member must be added before you can remove this team member',
    REQUIRED_VALID: 'At least one team member is required',
  },
  STATUS: {
    TYPE: 'Status must be a string',
    REQUIRED: 'Invalid status',
  },
  PI_ID: {
    TYPE: 'PI ID must be a number',
    REQUIRED: 'A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.',
    VALID: 'A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.',
  },
  PI_EMAIL: {
    TYPE: 'PI email must be a string',
    REQUIRED: 'Please enter a valid email address',
  },
  TITLE: {
    TYPE: 'Title must be a string',
    REQUIRED: 'Please enter a valid title',
  },
  NICK_NAME: {
    TYPE: 'Lay title name must be a string',
    REQUIRED: 'Please enter a valid lay title',
  },
  PROJECT_PURPOSE: {
    TYPE: 'Project purpose must be a string',
    REQUIRED: 'Please enter a valid purpose',
  },
  RESULT_SUMMARY: {
    TYPE: 'Result summary must be a string',
    REQUIRED: 'Please enter a valid result summary',
  },
  STUDY_LIMITATIONS: {
    TYPE: 'Study limitations must be a string',
    REQUIRED: 'Please enter a valid study limitations',
  },
  IS_ONLINE: {
    TYPE: 'Is online must be a boolean',
    REQUIRED: 'Is online is required',
  },
  IS_COMPLETED: {
    TYPE: 'Is completed must be a boolean',
    REQUIRED: 'Is completed is required',
  },
  IS_PPO: {
    TYPE: 'Is ppo must be a boolean',
    REQUIRED: 'Is ppo is required',
  },
  IS_POR: {
    TYPE: 'Is por must be a boolean',
    REQUIRED: 'Is por is required',
  },
  IS_BC_SUPPORT_UNIT: {
    TYPE: 'IsBCSupportUnit must be a boolean',
    REQUIRED: 'IsBCSupportUnit is required',
  },
  RESOURCE_WEBSITE_NAME: {
    TYPE: 'Resource website name must be a string',
    REQUIRED: 'Resource website name is required',
  },
  RESOURCE_WEBSITE_URL: {
    TYPE: 'Resource website URL must be a string',
    REQUIRED: 'Resource website URL is required',
  },
  RESOURCE_FILE_NAME: {
    TYPE: 'Resource file name must be a string',
    REQUIRED: 'Resource file name is required',
  },
  COMMENTS: {
    TYPE: 'Comments must be a string',
    REQUIRED: 'Comments are required',
  },
  ADMIN_COMMENTS: {
    TYPE: 'Admin comments must be a string',
    REQUIRED: 'Admin comments are required',
  },
  CONTACT1_NAME: {
    TYPE: 'Contact 1 name must be a string',
    REQUIRED: 'Contact 1 name is required',
  },
  CONTACT1_POSITION: {
    TYPE: 'Contact 1 position must be a string',
    REQUIRED: 'Contact 1 position is required',
  },
  CONTACT1_EMAIL: {
    TYPE: 'Contact 1 email must be a string',
    REQUIRED: 'Contact 1 email is required',
  },
  CONTACT1_PHONE: {
    TYPE: 'Contact 1 phone must be a string',
    REQUIRED: 'Contact 1 phone is required',
  },
  CONTACT1_PHONE_EXTENSION: {
    TYPE: 'Contact 1 phone extension must be a string',
    REQUIRED: 'Contact 1 phone extension is required',
  },
  CONTACT2_NAME: {
    TYPE: 'Contact 2 name must be a string',
    REQUIRED: 'Contact 2 name is required',
  },
  CONTACT2_POSITION: {
    TYPE: 'Contact 2 position must be a string',
    REQUIRED: 'Contact 2 position is required',
  },
  CONTACT2_EMAIL: {
    TYPE: 'Contact 2 email must be a string',
    REQUIRED: 'Contact 2 email is required',
  },
  CONTACT2_PHONE: {
    TYPE: 'Contact 2 phone must be a string',
    REQUIRED: 'Contact 2 phone is required',
  },
  CONTACT2_PHONE_EXTENSION: {
    TYPE: 'Contact 2 phone extension must be a string',
    REQUIRED: 'Contact 2 phone extension is required',
  },

  HEALTH_CATEGORIES: {
    TYPE: 'Please select health categories',
    REQUIRED: 'Please select health categories',
    REQUIRED_VALID: 'At least one  health categories is required',
  },
  HEALTH_REGIONS: {
    TYPE: 'Please selects health authority regions',
    REQUIRED: 'Please selects health authority regions',
  },
  PP_CO_INVERSTIGATOR: {
    TYPE: 'patient partner co-inverstigator must be a string',
  },
  PP_ORGANIZATION: {
    TYPE: 'Patient Partner Affiliated organization must be a string',
  },
  COLLABORATORS: {
    TYPE: 'Collaborators organization must be a string',
  },
  COLLABORATING_ORGANIZATION: {
    TYPE: 'Collaborating organization must be a string',
  },
  PHONE_NUMBER: {
    MATCH: 'Please enter a valid phone number',
    MIN: 'Please enter a valid phone number',
    MAX: 'Please enter a valid phone number. The field allows only 10 characters.',
  },
  ENGAGEMENT_METHOD: {
    TYPE: 'Engagement method must to be a string',
    REQUIRED: 'Please enter an engagement method',
  },
  ENGAGEMENT_NUMBER: {
    TYPE: 'Engagement method must to be a string',
    REQUIRED: 'Please enter an engagement method',
  },
  HOSPITAL: {
    TYPE: 'Hospital must be a string',
  },
  BUILDING: {
    TYPE: 'Building must be a string',
  },
  DEPARTMENT: {
    TYPE: 'Department must be a string',
  },
  STREET: {
    TYPE: 'Street address must be a string',
    REQUIRED: 'Please enter a street address',
  },
  CITY: {
    TYPE: 'City must be a string',
    REQUIRED: 'Please enter a city',
  },
  POSTAL_CODE: {
    TYPE: 'Postal code must be a string',
    MATCH: 'Please enter a valid postal code',
    REQUIRED: 'Please enter a postal code',
  },
  REGIONAL_CENTRE: {
    MIN: 'Please select a centre',
    REQUIRED: 'Please select a centre',
  },
  CREATED_AT: {
    REQUIRED: 'Missing created at date',
    TYPE: 'Created at mush be a date',
  },
};
