export const EXISTING_STUDY_RESULTS = {
  TITLE: 'Add Existing Study Result',

  BUTTONS: {
    BACK: 'Back',
  },
  LINK: {
    ADD: {
      LABEL: 'Add Result',
      PATH: '/somewehre',
    },
  },
  SEARCH_BAR: {
    PLACEHOLDER: 'Search for Project Name',
  },

  TABLE_HEADERS: [
    {
      header: 'Project Name',
      key: 'title',
    },
    {
      header: 'Type',
      key: 'type',
    },
    {
      header: 'Status',
      key: 'status',
    },
  ],
};
