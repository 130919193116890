import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput, Checkbox } from '../../../../../components';
import Button from '../../../../../components/Button';
import FormRemoveableInput from '../../../../../components/FormRemoveableInput';
import FormSection from '../../../../../components/FormSelection';
import FormSubtitle from '../../../../../components/FormSubtitle';
import { RESULTS_MAIN_FORM } from '../const';
import { TeamMemberSectionPropTypes } from '../types';
import * as Styled from '../styles';

export const TeamMemberSection = ({
  resultInfo,
  addInputHandler,
  changeAddedInputHandler,
  collaborators,
  removeInputHandler,
  teamMembers,
  updateResultInfoHandler,
  checkForReseacherAccount,
  checkboxInputHandler,
  errors,
}: TeamMemberSectionPropTypes) => (
  <FormSection
    title={RESULTS_MAIN_FORM.TEAM_MEMBERS.TITLE}
  >
    <Styled.FormChildrenWrapper>
      <TextInput
        label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PI.LABEL}
        description={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PI.DESCRIPTION}
        placeholder={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PI.PLACEHOLDER}
        onBlurAction={() => checkForReseacherAccount(resultInfo.pi_email || '', RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PI.NAME, `${resultInfo.pi_id}`)}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PI.NAME, value)}
        theme="form"
        error={errors?.pi_email || errors?.pi_id}
      />

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PPO.TITLE}
          margin="xsmall"
          isDisabled={resultInfo.is_por}
        />
        <Checkbox
          label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PPO.LABEL}
          isChecked={resultInfo.is_ppo}
          onChange={(checked) => checkboxInputHandler(
            RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PPO.NAME,
            checked,
            RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PPO.NAME,
          )}
          textTransform="none"
          disabled={resultInfo.is_por}
        />
      </div>

      {resultInfo.is_ppo && (
        <>
          <TextInput
            label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PP_CO_INVERSTIGATOR.LABEL}
            description={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PP_CO_INVERSTIGATOR.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PP_CO_INVERSTIGATOR.NAME, value)}
            theme="form"
            isRequired={false}
            error={errors?.PPO_co_inverstigator}
          />

          <TextInput
            label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.pp_AFFILIATION_ORGANIZATION.LABEL}
            description={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.pp_AFFILIATION_ORGANIZATION.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.pp_AFFILIATION_ORGANIZATION.NAME, value)}
            theme="form"
            error={errors?.PPO_organization}
            isRequired={false}
          />
        </>
      )}

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.TITLE}
          margin="xsmall"
          link={{
            url: RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.LINK.PATH,
            label: RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.LINK.LABEL,
          }}
          isDisabled={resultInfo.is_ppo}
        />
        <Styled.FormDescriptionStyles isDisabled={resultInfo.is_ppo}>
          {RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.DESCRIPTION}
        </Styled.FormDescriptionStyles>
        <Checkbox
          label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.LABEL}
          isChecked={resultInfo.is_por}
          onChange={(checked) => checkboxInputHandler(
            RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.NAME,
            checked,
            RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.POR.NAME,
          )}
          textTransform="none"
          disabled={resultInfo.is_ppo}
        />
      </div>

      {resultInfo.is_por && (
        <>
          <TextInput
            label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PP_CO_INVERSTIGATOR.LABEL}
            description={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PP_CO_INVERSTIGATOR.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.PP_CO_INVERSTIGATOR.NAME, value)}
            theme="form"
            error={errors?.POR_co_inverstigator}
            isRequired={false}
          />

          <TextInput
            label={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.pp_AFFILIATION_ORGANIZATION.LABEL}
            description={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.pp_AFFILIATION_ORGANIZATION.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.pp_AFFILIATION_ORGANIZATION.NAME, value)}
            error={errors?.POR_organization}
            theme="form"
            isRequired={false}
          />
        </>
      )}

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.COLLABORATORS.TITLE}
          margin="xsmall"
        />
        <Styled.FormDescriptionStyles theme="secondary">
          {RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.COLLABORATORS.DESCRIPTION}
        </Styled.FormDescriptionStyles>
        {Object.keys(collaborators).length > 0 && (
          <Styled.InputsWrapper>
            {Object.entries(collaborators).map(([key, value]) => (
              <FormRemoveableInput
                id={key}
                field="collaborator"
                textValue={value}
                handleChange={changeAddedInputHandler}
                handleRemoveInput={removeInputHandler}
              />
            ))}
          </Styled.InputsWrapper>
        )}
        <Button
          id={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.COLLABORATORS.BUTTON.ADD}
          onClick={() => addInputHandler('collaborator')}
          buttonWidth="fit-content"
        >
          <Styled.ButtonChildrenWrapper>
            <FontAwesomeIcon icon="plus" />
            {RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.COLLABORATORS.BUTTON.ADD}
          </Styled.ButtonChildrenWrapper>
        </Button>
      </div>

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.TEAM_MEMBER.TITLE}
          margin="xsmall"
        />
        <Styled.FormDescriptionStyles theme="secondary">
          {RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.TEAM_MEMBER.DESCRIPTION}
        </Styled.FormDescriptionStyles>
        <Styled.InputsWrapper>
          {Object.entries(teamMembers).map(([key, value]) => (
            <FormRemoveableInput
              id={key}
              field="teamMember"
              textValue={value.email}
              onBlurAction={() => checkForReseacherAccount(value.email, RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.TEAM_MEMBER.NAME, key)}
              handleChange={changeAddedInputHandler}
              handleRemoveInput={removeInputHandler}
              error={errors ? errors[key] : ''}
            />
          ))}
        </Styled.InputsWrapper>

        <Button
          id={RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.TEAM_MEMBER.BUTTON.ADD}
          onClick={() => addInputHandler('teamMember')}
          buttonWidth="fit-content"
        >
          <Styled.ButtonChildrenWrapper>
            <FontAwesomeIcon icon="plus" />
            {RESULTS_MAIN_FORM.TEAM_MEMBERS.INPUTS.TEAM_MEMBER.BUTTON.ADD}
          </Styled.ButtonChildrenWrapper>
        </Button>
      </div>
    </Styled.FormChildrenWrapper>
  </FormSection>
);
