import styled from 'styled-components';
import toRem from '../../../../utils/toRem';

export const SideMenuWrapper = styled.div`
  padding: ${toRem(24)} ${toRem(14)};
  display: flex;
  flex-direction: column;
  gap: ${toRem(56)};
`;

export const StatusWrapper = styled.div`
  width: fit-content;
`;
