import React from 'react';
import AppWrapper from '../../components/AppWrapper';

import ResearcherDashboard from './ResearcherDashboard';
import VolunteerDashboard from '../../components/VolunteerDashboard';
import { useDashboard } from '../../hooks/useDashboard';
import { ResearcherDashboardHero, VolunteerDashboardHero } from '../../components/DashboardHero';
import { Modal } from '../../components/Modal';
import { ResultsTypeModal } from './ResultsTypeModal';
import { ExistingStudyResults } from './ExistingStudyResults';

const Dashboard = () => {
  const hook = useDashboard();

  return (
    <AppWrapper footer alternateFooter theme="secondary">
      {hook.user.type === 'volunteer' ? (
        <VolunteerDashboardHero
          userName={hook.user.first_name}
          searchValue={hook.searchValue}
          searchValueHandler={hook.setSearchValueHandler}
        />
      ) : (
        <ResearcherDashboardHero
          userName={hook.user.first_name}
          bypass={hook.user.bypass}
          openModelHandler={hook.setModalIsOpenHandler}
        />
      )}
      {
        hook.user.type === 'researcher' ? (
          <ResearcherDashboard
            tableData={hook.tableData}
            hasSiteCode={!!hook.user.site_code}
            currentTab={hook.researcherTab}
            setCurrentTab={hook.setResearcherTab}
            siteVolunteerData={hook.siteVolunteerData}
            searchValue={hook.searchValue}
            searchHandler={hook.setSearchValueHandler}
            isLoading={hook.isLoading}
          />
        ) : (
          <VolunteerDashboard
            user={hook.user}
            searchValue={hook.searchValue}
          />
        )
      }

      <Modal isOpen={hook.modalIsOpen}>
        {hook.selectStudyModal ? (
          <ExistingStudyResults
            backHandler={hook.setSelectStudyModelHandler}
            closeHandler={hook.setModalIsOpenHandler}
            searchHandler={hook.setresultSearchValueHandler}
            searchValue={hook.resultSearchValue}
            projects={hook.projectResultFilter}
            selectedProject={hook.selectedProject}
            setSelectedProjectHandler={hook.setSelectedProjectHandler}
            sortBy={hook.sortBy}
            sortDirection={hook.sortDirection}
            sortHandler={hook.sortHandler}
            navigateToNewResultPage={hook.navigateToNewResultPage}
          />
        ) : (
          <ResultsTypeModal
            radioHandler={hook.setResultsTypeHandler}
            radioValue={hook.resultsType}
            closeHandler={hook.setModalIsOpenHandler}
            submitHandler={hook.addResultsSubmit}
            resultsType={hook.resultsType}
          />
        )}
      </Modal>
    </AppWrapper>
  );
};

export default Dashboard;
