import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXISTING_STUDY_RESULTS } from '../ExistingStudyResults/const';
import { projectStatus } from '../../../utils';
import StatusTag from '../../../components/StatusTag';
import Button from '../../../components/Button';
import * as Styled from './styles';
import { RadioInput } from '../../../components/RadioInput';
import { ProjectResultType } from '../../../hooks/useDashboard';

type ProjectResultGridPropTypes = {
  projects: ProjectResultType[]
  sortHandler: (column: string) => void
  sortBy: string
  sortDirection: string
  selectedProject?: string
  setSelectedProjectHandler: (id: string) => void
}
export const ProjectResultGrid = ({
  projects,
  sortBy,
  sortDirection,
  sortHandler,
  selectedProject,
  setSelectedProjectHandler,
}: ProjectResultGridPropTypes) => (
  <Styled.TableGridStyled>
    <Styled.TableHeader>
      {EXISTING_STUDY_RESULTS.TABLE_HEADERS.map((header) => (
        <div key={header.key}>
          <Button
            id={`headerBtn-${header.key}`}
            theme="plainPopover"
            onClick={() => {
              sortHandler(header.key);
            }}
            buttonWidth="100%"
          >
            <Styled.HeaderButton>
              {header.header}
              {sortBy === header.key && sortDirection === 'desc' ? (
                <FontAwesomeIcon icon="caret-down" />
              ) : (
                <FontAwesomeIcon icon="caret-up" />
              )}
            </Styled.HeaderButton>
          </Button>
        </div>
      ))}
    </Styled.TableHeader>

    {projects.map((project) => (
      <Styled.TableBodyStyled key={project.id}>
        <Styled.GridCellStyles>
          <RadioInput
            id={project.id}
            name={project.id}
            value={project.id}
            checked={selectedProject === project.id}
            onChange={() => setSelectedProjectHandler(project.id)}
          />
          <Styled.LabelStyled htmlFor={project.id}>
            {project.title}
          </Styled.LabelStyled>
        </Styled.GridCellStyles>
        <Styled.GridCellStyles theme="secondary">
          {project.type === 'ppo' ? 'PPO' : 'Study'}
        </Styled.GridCellStyles>
        <Styled.GridCellStyles>
          <StatusTag
            label={projectStatus[project.status]}
            theme={project.status}
          />
        </Styled.GridCellStyles>
      </Styled.TableBodyStyled>
    ))}
  </Styled.TableGridStyled>
);
