import styled from 'styled-components';
import { fonts } from '../../../styles/theme';
import toRem from '../../../utils/toRem';

export const ResultWraper = styled.div`
  display: flex;
  flex-direction: column;
  ${fonts.font1.H15};
  line-height: 1.33rem;
  
  @media (min-width: 768px) {
    flex-direction: row;
    column-gap: ${toRem(40)};
  }
`;
