import styled from 'styled-components';
import { fonts, colours } from '../../../../../styles/theme';
import toRem from '../../../../../utils/toRem';

export const SectionStyled = styled.section`
  margin: ${toRem(50)} 0;
  padding: 0 0 ${toRem(50)};
  ${fonts.font1.H16};
  line-height: ${toRem(26)};
  border-bottom: 1px solid ${colours.colour29};
  color: ${colours.colour6};
`;

export const TitleStyled = styled.h3`
  ${fonts.font1.H24B};
  margin-bottom: ${toRem(30)};
`;

export const HealthCategoryWrapper = styled.div`
display: grid;
grid-template-columns: repeat(3, minmax(0, 1fr));
`;
