import styled from 'styled-components';
import toRem from '../../../utils/toRem';
import { colours } from '../../../styles/theme';

export const SpiltSectionStyled = styled.section`
  position: relative;
  height: 100%;
  display: grid;

  @media (min-width: 820px) {
    grid-template-columns: auto 300px;
    gap: ${toRem(14)};
  }
`;

export const StickyDivStyled = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  bottom: 0;
  background: ${colours.colour4};
  z-index: 1;
  width: 100%;
  border-top: 1px solid ${colours.colour41};
  padding-top: 0.625rem;
  
  @media (min-width: 820px) {
    position: sticky;
    top: 0;
    border: none;
    max-height: 100vh;
    overflow: auto;

    /* hide scroll bar for side menu */
    -ms-overflow-style: none; 
    scrollbar-width: none;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
    }
  }
`;
